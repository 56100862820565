import {
  Drawer,
  Toolbar,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Box,
  IconButton,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";
import CloseIcon from "@mui/icons-material/Close";
import DashboardIcon from "@mui/icons-material/Dashboard";
import LockClockIcon from "@mui/icons-material/LockClock";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import HelpCenterIcon from "@mui/icons-material/HelpCenter";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PsychologyIcon from "@mui/icons-material/Psychology";
import { SIDEBAR_WIDTH } from "../constants";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import FeaturedVideoIcon from "@mui/icons-material/FeaturedVideo";
import QuizIcon from "@mui/icons-material/Quiz";
import SavingsIcon from "@mui/icons-material/Savings";
import Person3Icon from "@mui/icons-material/Person3";
import NoteIcon from "@mui/icons-material/Note";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import PaidIcon from "@mui/icons-material/Paid";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import { PropsWithoutRef, useEffect, useState } from "react";
import DiscountIcon from "@mui/icons-material/Discount";
import SettingsIcon from "@mui/icons-material/Settings";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import useAuth from "../hooks/useAuth";

interface Props {
  mobileOpen: boolean;
  handleSidebarToggle: () => void;
}

export default function Sidebar({
  mobileOpen,
  handleSidebarToggle,
}: PropsWithoutRef<Props>) {
  const UserData = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const userPermissions =
    UserData?.role === 1
      ? UserData?.permissions
      : UserData?.permissions?.length
        ? (UserData?.permissions)
        : [];

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Customers",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manage-customers",
    },
    {
      label: "Manage Categories",
      icon: <ListAltIcon />,
      key: "3",
      selected: "/manage-categories",
    },
    {
      label: "Manage Product Specifications",
      icon: <ContentPasteIcon />,
      key: "4",
      selected: "/manage-products",
    },
    {
      label: "Inventory Management",
      icon: <SubscriptionsIcon />,
      key: "5",
      selected: "/inventory-management",
    },
    {
      label: "Product Order Management",
      icon: <ProductionQuantityLimitsIcon />,
      key: "6",
      selected: "/product-order-management",
    },
    {
      label: "Manage Bulk Orders",
      icon: <ProductionQuantityLimitsIcon />,
      key: "7",
      selected: "/manage-bulk-orders",
    },

    {
      label: "Manage Payments",
      icon: <PaidIcon />,
      key: "8",
      selected: "/manage-revenue",
    },

    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "9",
      selected: "/manage-subAdmin",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "10",
      selected: "/analytics",
    },
    {
      label: "Discount and Coupon Management",
      icon: <DiscountIcon />,
      key: "11",
      selected: "/discount-and-coupon",
    },
    {
      label: "Manage Geoforce",
      icon: <AddLocationAltIcon />,
      key: "12",
      selected: "/manage-geofence",
    },
    {
      label: "Manage Banners",
      icon: <ViewCarouselIcon />,
      key: "13",
      selected: "/ad-banner-management",
    },
    {
      label: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "14",
      selected: "/manage-notifications",
    },
    {
      label: "Manage Factory Tour",
      icon: <ViewCarouselIcon />,
      key: "15",
      selected: "/manage-factory-tour",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "16",
      selected: "/cms",
    },
  ];

  const [list, setList] = useState<any>(
    UserData?.role === 1 ? items : userPermissions
  );


  useEffect(() => {
    let arr = [];
    if (UserData?.role === 1) {
      setList(items);
    } else if (userPermissions?.length) {
      const permission = userPermissions;
      arr = items.map((item) => {
        let idx = -1;
        idx = permission?.findIndex((ele: any) => ele?.label === item?.label);
        if (idx > -1) {
          if (permission[idx].isView) {
            return item;
          }
          return false;
        }
      });
      setList(arr);
    }
  }, [UserData]);

  const handleNavigation = () => {
    if (UserData?.role === 2) {
      const permissions = (UserData?.permissions);
      if (UserData?.role === 2 && permissions?.length > 0) {
        const firstPermissionLabel = permissions[0]?.label;
        const redirectPath = items.find(
          (item) => item.label === firstPermissionLabel
        )?.selected;

        if (redirectPath) {
          navigate(redirectPath);
        } else {
          console.error("Invalid permission label for redirection");
          // Handle the case where the label doesn't match any item in the array
        }
      }
    } else {
      // Default redirect for other roles or when no permissions are available
      navigate("/dashboard");
    }
  };


  return (
    <Box
      component="nav"
      sx={{ flexShrink: { lg: 0 } }}
      className="sidebar-main-div"
    >
      <Drawer
        className="sidebar_drawer"
        variant="temporary"
        open={mobileOpen}
        onClose={handleSidebarToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", lg: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: SIDEBAR_WIDTH,
          },
        }}
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              // onClick={() => navigate("/dashboard")}
              onClick={handleNavigation}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
            .filter((ele: any) => ele !== undefined)
            .map((item: any) => {
              const isSelected = location.pathname.includes(item?.selected);
              return (
                <List
                  key={item?.label}
                  className="sidebr-lst"
                  sx={{ padding: 0 }}
                >
                  <ListItemButton
                    className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                    onClick={() => navigate(item?.selected)}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText
                      className="lstitm-txt"
                      primary={item?.label}
                    />
                  </ListItemButton>
                </List>
              );
            })
          : null}
      </Drawer>
      <Drawer
        variant="permanent"
        className="sidebar_drawer"
        sx={{
          display: { xs: "none", lg: "block" },
          "& .MuiDrawer-paper": {
            width: SIDEBAR_WIDTH,
            boxSizing: "border-box",
          },
        }}
        open
      >
        <Toolbar className="sidebar-logo" sx={{ position: "relative" }}>
          <figure>
            <img
              src="/static/images/logo.png"
              alt=""
              // onClick={() => navigate("/dashboard")}
              onClick={handleNavigation}
            />
          </figure>
          <IconButton
            sx={{
              position: "absolute",
              top: "10px",
              right: "10px",
              color: "white",
              display: { lg: "none" },
            }}
            onClick={handleSidebarToggle}
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
        {list?.length
          ? list
            .filter((ele: any) => ele !== undefined)
            .map((item: any) => {
              const isSelected = location.pathname.includes(item?.selected);
              return (
                <List
                  key={item?.label}
                  className="sidebr-lst"
                  sx={{ padding: 0 }}
                >
                  <ListItemButton
                    className={`lst-itm ${isSelected ? "Mui-selected" : ""}`}
                    onClick={() => navigate(item?.selected)}
                  >
                    <ListItemIcon>{item?.icon}</ListItemIcon>
                    <ListItemText
                      className="lstitm-txt"
                      primary={item?.label}
                    />
                  </ListItemButton>
                </List>
              );
            })
          : null}
      </Drawer>
    </Box>
  );
}
