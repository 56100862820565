import {
  Box,
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Switch,
  FormControl,
  Typography,
  Select,
  MenuItem,
  Tooltip,
  Modal,
  TextField,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { ChangeEvent, useEffect, useState } from "react";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useNavigate } from "react-router-dom";
import SearchBar from "../../components/SearchBar";
import { isValidInput } from "../../utils/validations";
import { generateResponsiveStyle } from "../../utils/ModalStyle";
import { CommonBody, OrderData } from "../../types/General";
import { Pagination } from "../../components";
import { useUpdateStatusMutation } from "../../services/orders";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { showError, showToast } from "../../constants/toast";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

interface TabProps {
  Data: OrderData[];
  count: number;
  fetchOrderData: () => void;
  page: number;
  onPageChange: (newPage: number) => void;
}


const PENDING: React.FC<TabProps> = ({ Data, count, fetchOrderData, page, onPageChange }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState<boolean>(false)
  const style = generateResponsiveStyle();
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [selectedStatus, setSelectedStatus] = useState<number>(0);
  const [days, setDays] = useState<string>("");
  const [updateStatus] = useUpdateStatusMutation();
  const [orderId, setOrderId] = useState<string>("");
  let totalPages = Math.ceil(count / 10);
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Product Order Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  const handleDaysChange = (e: any) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) { // Ensure the input contains only digits
      setDays(value);
    }
  };


  //update user status api
  const updateOrderStatus = async (id: string, status: number, days: string) => {
    setSelectedStatus(status);
    const data = {
      status: status,
      estimatedDeliveryDays: Number(days)
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateStatus({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Order status updated successfully");
        setOpen(false);
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating order status");
    }
  };

  const handleSave = async () => {
    await updateOrderStatus(orderId, selectedStatus, days);
    setOpen(false);

  };

  console.log(selectedStatus, "selectedStatus");
  useEffect(() => {
    if (selectedStatus === 2) {
      setOpen(true);
      fetchOrderData();
    } if (selectedStatus === 4) {
      updateOrderStatus(orderId, selectedStatus, "")
      fetchOrderData();
    }
  }, [selectedStatus]);

  useEffect(() => {
    fetchOrderData()
  }, [page])

  useEffect(() => {
    checkPermission();
  }, [UserData])

  return (
    <div>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Order Id</TableCell>
              <TableCell align="center">Customer Name</TableCell>
              <TableCell align="center">Email</TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Total cost</TableCell>
              <TableCell align="center">Payment Received</TableCell>
              <TableCell align="center">Balance Payment</TableCell>              <TableCell align="center">Product status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {
            Data?.length ? (
              Data?.map((row, i) => {
                return (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY") || "-"}</TableCell>
                      <TableCell align="center" > {row?.orderId || "-"}</TableCell>
                      <TableCell align="center">{row?.user?.fullName ? (row?.user?.fullName) : (row?.user?.firstName + " " + row?.user?.lastName) || "-"}</TableCell>
                      <TableCell align="center">{row?.address?.email || "-"}</TableCell>
                      <TableCell align="center">{row?.productVariant?.name || "-"}</TableCell>
                      <TableCell align="center">AED{" "}{row?.totalPriceOfProduct || "-"}</TableCell>
                      <TableCell align="center">AED{" "}{row?.advanceAmountPaid || "-"}</TableCell>
                      <TableCell align="center">AED{" "}{row?.pendingAmountLeft || "-"}</TableCell>
                      <TableCell align="center">
                        <FormControl fullWidth>
                          {hidePermission?.isEdit || UserData?.role === 1 ? (
                            <Select
                              className="select_div "
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={row?.status || 1}
                              onChange={(event) => {
                                setSelectedStatus(event.target.value as number);
                                setOrderId(row?._id);
                              }}
                            >
                              <MenuItem value={1} disabled>Pending</MenuItem>
                              <MenuItem value={2}>Accept</MenuItem>
                              <MenuItem value={4}>Reject</MenuItem>
                            </Select>
                          ) : null}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <Box className="table_actions">
                          <Tooltip title="View">
                            <IconButton
                              onClick={() =>
                                navigate(`/product-order-management/details/${row?._id}`, { state: { type: "PENDING" } })
                              }
                            >
                              <VisibilityIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>

                );
              })
            ) : (
              <TableBody>
                <TableCell
                  align="center"
                  colSpan={10}
                  sx={{ color: "#051140" }}
                >
                  No data Found
                </TableCell>
              </TableBody>
            )}
        </Table>
      </TableContainer>
      <Pagination
        module={Data}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="logout_mdl"
      >
        <Box sx={style}>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
          >
            Estimated number of days in delivery
          </Typography>

          <TextField
            hiddenLabel
            type={"text"}
            name="days"
            id="days"
            variant="outlined"
            fullWidth
            placeholder="Number of days"
            className="text_field"
            value={days}
            // onChange={(e) => setDays(e.target.value)}
            onChange={handleDaysChange}
          />
          <div className="flexdiv">
            <Button
              className="yesBtn"
              sx={{ color: "#252525", border: "1px solid #252525" }}
              onClick={handleSave}
            >
              Save
            </Button>
          </div>
        </Box>
      </Modal>
    </div >
  );
};

export default PENDING;
