import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { OrderData, CommonBody, BulkOrderData } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type ResponceDataBulk = {
    bulkOrders: BulkOrderData[],
    count: number,
    pages: number
}
type ResponceData = {
    data: OrderData[],
    pages: number,
    count: number
}
export const orderApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getOrders: builder.query<
            CommonResponseType & { data: ResponceData },
            { type?: number | undefined, query?: string | undefined, timeFilter?: number, page?: number, fromDate?: string, toDate?: string }
        >({
            query: ({ type, query, timeFilter, page, fromDate, toDate }) => ({
                url: `${END_POINTS.orders}?search=${query}&type=${type}&page=${page}&timeFilter=${timeFilter}&fromDate=${fromDate}&toDate=${toDate}`,
                method: "GET"
            })
        }),
        getOrdersByUser: builder.query<
            CommonResponseType & { data: ResponceData },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.getOrdersByUser}/${id}`,
                method: "GET"
            })
        }),

        getOrderById: builder.query<
            CommonResponseType & { data: OrderData },
            { id: string | undefined }>({
                query: ({ id }) => ({
                    url: `${END_POINTS.orders}/${id}`,
                    method: "GET"
                })
            }),

        updateStatus: builder.mutation<
            CommonResponseType & { data: OrderData },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.updateOrderStatus}/${id}`,
                method: "PUT",
                body
            })
        }),

        //bulk orders
        getBulkOrders: builder.query<
            CommonResponseType & { data: ResponceDataBulk },
            { type?: number | undefined, query?: string | undefined, timeFilter?: number, page?: number }
        >({
            query: ({ type, query, timeFilter, page }) => ({
                url: `${END_POINTS.bulkOrder}?search=${query}&type=${type}&page=${page}&timeFilter=${timeFilter}`,
                method: "GET"
            })
        }),

        getBulkOrderById: builder.query<
            CommonResponseType & { data: BulkOrderData },
            { id: string | undefined }>({
                query: ({ id }) => ({
                    url: `${END_POINTS.bulkOrder}/${id}`,
                    method: "GET"
                })
            }),
    })
})

export const {
    useLazyGetOrdersQuery,
    useLazyGetOrdersByUserQuery,
    useLazyGetOrderByIdQuery,
    useUpdateStatusMutation,
    useLazyGetBulkOrdersQuery,
    useLazyGetBulkOrderByIdQuery
} = orderApi;