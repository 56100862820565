import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/ModalStyle";
import { showError } from "../constants/toast";
import { useLazyGetEarningExportsQuery, useLazyGetOrderExportsQuery } from "../services/dashboard";

// Eraning files
type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

const ExportModal3 = ({ open, setOpen }: props) => {
    const style = generateResponsiveStyle();
    const [getPayments] = useLazyGetEarningExportsQuery();

    const exportFileCsv = async () => {
        try {
            const res = await getPayments({}).unwrap();
            if (res?.statusCode === 200) {
                window.open(res?.data?.exportUrlCsv || "");
            }
        } catch (error: any) {
            showError(error?.message);
        }
    };

    const exportFileExcel = async () => {
        try {
            const res = await getPayments({}).unwrap();
            if (res?.statusCode === 200) {
                window.open(res?.data?.exportUrlXl || "");
            }
        } catch (error: any) {
            showError(error?.message);
        }
    };

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="logout_mdl"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
                >
                    Please select a file format
                </Typography>

                <div className="flexdiv">
                    <Button
                        className="yesBtn"
                        sx={{ color: "#252525", border: "1px solid #252525" }}
                        onClick={() => {
                            setOpen(false);
                            exportFileCsv();
                        }}
                    >
                        Export Csv
                    </Button>
                    <Button
                        sx={{ color: "#252525", border: "1px solid #252525" }}
                        onClick={() => {
                            setOpen(false);
                            exportFileExcel();
                        }}
                    >
                        Export Excel
                    </Button>
                </div>
            </Box>
        </Modal >
    );
};

export default ExportModal3;
