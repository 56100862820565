import {
  Box,
  Button,
  Container,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { usePostChangePasswordMutation } from "../../../services/auth";
import { showError, showToast } from "../../../constants/toast";
import { generateEncryptedKeyBody } from "../../../utils/crypto";
import { CommonBody } from "../../../types/General";

const ChangePassword = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [oldPassword, setOldPassword] = useState<boolean>(false);
  const [cnfmPassword, setCnfmPassword] = useState<boolean>(false);
  const [ChangePasswordMutation, { isLoading }] =
    usePostChangePasswordMutation();


  const formik = useFormik({
    initialValues: {
      newPassword: "",
      passwordConfirmation: "",
      oldPassword: "",
    },
    validationSchema: Yup.object({
      oldPassword: Yup.string()
        .label("Old Password")
        .required("Old password is required."),

      newPassword: Yup.string()
        .label("new Password")
        .required("New password is required.")
        .notOneOf(
          [Yup.ref("oldPassword"), null],
          "New password cannot be same as old password."
        )
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
          "Enter a strong password"),

      passwordConfirmation: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match.")
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
          "Enter a strong password")
        .required("Confirm password is required."),
    }),

    onSubmit: async () => {
      formik.setSubmitting(true);

      let data = {
        oldPassword: formik.values.oldPassword,
        password: formik.values.newPassword,
      };

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await ChangePasswordMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "Password changed successfully" || "");
          navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error, "errorrr");
      }
      formik.setSubmitting(false);
    },
  });


  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", position: "relative" }}>
            <IconButton
              onClick={() => navigate("/dashboard")}
              sx={{ p: 0, position: "absolute", left: 0, color: "#1d1d1d" }}
            >
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" sx={{ color: "#1d1d1d" }}>
              Change Password
            </Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Old Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Old Password"
                fullWidth
                id="oldPassword"
                name="oldPassword"
                type={oldPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.oldPassword}
                helperText={
                  formik.touched.oldPassword && formik.errors.oldPassword
                }
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setOldPassword(!oldPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {oldPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">New Password</Typography>
              <TextField
                className="text_field"
                placeholder="New Password"
                fullWidth
                id="newPassword"
                name="newPassword"
                type={cnfmPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.newPassword}
                helperText={
                  formik.touched.newPassword && formik.errors.newPassword
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setCnfmPassword(!cnfmPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {cnfmPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Confirm Password</Typography>
              <TextField
                className="text_field"
                placeholder="Confirm Password"
                fullWidth
                id="passwordConfirmation"
                name="passwordConfirmation"
                type={showPassword ? "text" : "password"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.passwordConfirmation}
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                // type={showPassword ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end" className="eye_btn">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>

            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default ChangePassword;
