import {
    Box,
    Button,
    Card,
    CardContent,
    CardMedia,
    Grid,
    TextField,
    Typography,
    Input,
    FormControl,
    Select,
    MenuItem,
    SelectChangeEvent,
    Autocomplete,
    CircularProgress,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CancelIcon from "@mui/icons-material/Cancel";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { UploadMedia, UploadVideo } from "../../utils/mediaUpload";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, FactoryTour } from "../../types/General";
import { useLazyGetFactoryTourByIdQuery, usePostFactoryTourMutation, useUpdateFactoryTourByIdMutation } from "../../services/factoryTour";
import { Description } from "@mui/icons-material";


const AddFactoryTour = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [getFactoryById] = useLazyGetFactoryTourByIdQuery();
    const [updateFactoryTour] = useUpdateFactoryTourByIdMutation();
    const [addFactory] = usePostFactoryTourMutation();
    const [open, setOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
    const [page, setPage] = useState<number>(1);
    const [factory, setFactory] = useState<FactoryTour>();
    const [image, setImage] = useState<string>("");
    const [video, setVideo] = useState<string>("");



    const handleImageUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : "";
        const res = await UploadMedia(file);
        if (res?.statusCode === 200) {
            setImage(res?.data);
        } else {
            showError(res?.message);
        }
    };

    const handleVideoUpload = async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        const files = event.target;
        const file = files?.files?.length ? files?.files[0] : "";
        const res = await UploadVideo(file);
        if (res?.statusCode === 200) {
            setVideo(res?.data);
        } else {
            showError(res?.message);
        }
    };

    const getBannerDetails = async (id: string | undefined) => {
        try {
            const res = await getFactoryById({ id }).unwrap();
            if (res?.statusCode === 200) {
                setFactory(res?.data)
                setImage(res?.data?.image);
                setVideo(res?.data?.video)
            }
        } catch (error: any) {
            showError(error?.data?.message || "");
        }
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: factory?.title || "",
            title_ar: factory?.title_ar || "",
            description: factory?.description || "",
            description_ar: factory?.description_ar || "",


        },
        validationSchema: Yup.object({
            title: Yup.string()
                .required("Title is required")
                .min(2, "Minimum 2 characters are required")
                .max(80, "Maximum 80 characters are allowed"),
            title_ar: Yup.string()
                .required("Title is required")
                .min(2, "Minimum 2 characters are required")
                .max(80, "Maximum 80 characters are allowed"),
            description: Yup.string()
                .required("Description is required")
                .min(2, "Minimum 2 characters are required")
                .max(80, "Maximum 80 characters are allowed"),
            description_ar: Yup.string()
                .required("Description is required")
                .min(2, "Minimum 2 characters are required")
                .max(80, "Maximum 80 characters are allowed"),

        }),

        onSubmit: async (values) => {
            formik.setSubmitting(true);
            let data = {
                title: values.title,
                title_ar: values.title_ar,
                image: image,
                video: video,
                description: values.description,
                description_ar: values.description_ar,
            };
            console.log(data, "data")

            if (!image) {
                showError("Image is required")
                return;
            }
            if (!video) {
                showError("Video is required")
                return;
            }
            if (id) {
                try {
                    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
                    const response = await updateFactoryTour({ body: encryptedBody, id }).unwrap();
                    if (response?.statusCode === 200) {
                        showToast("Factory Tour updated successfully!");
                        navigate("/manage-factory-tour", { replace: true });
                    }
                } catch (error: any) {
                    showError(error?.data?.message || "");
                }
            } else {
                try {
                    let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
                    const response = await addFactory(encryptedBody).unwrap();
                    if (response?.statusCode === 200) {
                        showToast("Factory Tour added successfully!");
                        navigate("/manage-factory-tour", { replace: true });
                    }
                } catch (error: any) {
                    showError(error?.data?.message || "");
                }
            }
        },
    });



    useEffect(() => {
        if (id) {
            getBannerDetails(id);
        }
    }, []);

    return (
        <>
            <MainContainer>
                <div className="main_loyout">
                    <div className="dashboard">
                        <h1>{id ? "Edit Factory Tour" : "Add Factory Tour"}</h1>
                        <Button
                            className="btn btn_primary"
                            onClick={() => {
                                navigate("/manage-factory-tour");
                            }}
                        >
                            Back
                        </Button>
                    </div>
                    <Card className="cards">
                        <form onSubmit={formik.handleSubmit}>
                            <CardContent sx={{ p: 1 }}>
                                <Grid container spacing={2}>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">Image</Typography>
                                        {image ? (
                                            <div className="upload_image_preview2">
                                                <CardMedia component="img" image={image} alt="photo" />
                                                <CancelIcon
                                                    onClick={() => {
                                                        setImage("");
                                                    }}
                                                />
                                            </div>
                                        ) : (
                                            <Box className="upload_image_bnr">
                                                <label htmlFor="icon-button-file">
                                                    <Input
                                                        sx={{ display: "none" }}
                                                        id="icon-button-file"
                                                        type="file"
                                                        inputProps={{
                                                        }}
                                                        onChange={(
                                                            e: React.ChangeEvent<HTMLInputElement>
                                                        ) => handleImageUpload(e)}
                                                    />
                                                    <Button component="span" >
                                                        <img
                                                            src={
                                                                image
                                                                    ? image
                                                                    : "/static/images/prod_placeholder.png"
                                                            }
                                                            alt=""
                                                        />
                                                        <AddIcon width={"100%"} height={"100%"} />
                                                    </Button>
                                                </label>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">Video (upto 30 Mb)</Typography>
                                        {video ? (
                                            <div className="upload_image_preview2">
                                                <video src={video} controls autoPlay={false} width="100%" height="100%" style={{ objectFit: "cover" }}>
                                                    <CancelIcon
                                                        onClick={() => {
                                                            setVideo("");
                                                        }}
                                                    />
                                                </video>
                                            </div>
                                        ) : (
                                            <Box className="upload_image_bnr">
                                                <label htmlFor="video-upload">
                                                    <Input
                                                        sx={{ display: "none" }}
                                                        id="video-upload"
                                                        type="file"
                                                        inputProps={{}}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                            handleVideoUpload(e)
                                                        }
                                                    />
                                                    <Button component="span">
                                                        {/* <CameraAltIcon /> */}
                                                        <AddIcon width={"100%"} height={"100%"} />
                                                    </Button>
                                                </label>
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">
                                            Title (English)
                                        </Typography>
                                        <TextField
                                            hiddenLabel
                                            type={"text"}
                                            name="title"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Title"
                                            className="text_field"
                                            id="title"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title}
                                            onChange={(val) => {
                                                formik.handleChange(val);
                                            }}
                                            helperText={formik.touched.title && formik.errors.title}
                                            inputProps={{ maxLength: 30 }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">
                                            Title (Arabic)
                                        </Typography>
                                        <TextField
                                            hiddenLabel
                                            type={"text"}
                                            name="title_ar"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Title"
                                            className="text_field"
                                            id="title_ar"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.title_ar}
                                            onChange={(val) => {
                                                formik.handleChange(val);
                                            }}
                                            helperText={formik.touched.title_ar && formik.errors.title_ar}
                                            inputProps={{ maxLength: 30 }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">
                                            Description (English)
                                        </Typography>
                                        <TextField
                                            hiddenLabel
                                            type={"text"}
                                            name="description"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Description"
                                            className="text_field"
                                            id="description"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description}
                                            onChange={(val) => {
                                                formik.handleChange(val);
                                            }}
                                            helperText={formik.touched.description && formik.errors.description}
                                            inputProps={{ maxLength: 30 }}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={6} md={6} sm={6} xs={12}>
                                        <Typography className="custom_label">
                                            Description (Arabic)
                                        </Typography>
                                        <TextField
                                            hiddenLabel
                                            type={"text"}
                                            name="description_ar"
                                            variant="outlined"
                                            fullWidth
                                            placeholder="Title"
                                            className="text_field"
                                            id="description_ar"
                                            onBlur={formik.handleBlur}
                                            value={formik.values.description_ar}
                                            onChange={(val) => {
                                                formik.handleChange(val);
                                            }}
                                            helperText={formik.touched.description_ar && formik.errors.description_ar}
                                            inputProps={{ maxLength: 30 }}
                                        ></TextField>
                                    </Grid>

                                </Grid>
                                <div className="form_btn">
                                    <Button
                                        size="large"
                                        type="submit"
                                        className="btn btn_primary"
                                    >
                                        Save
                                    </Button>
                                </div>
                            </CardContent>
                        </form>
                    </Card>
                </div>
            </MainContainer>
        </>
    );
};

export default AddFactoryTour;
