import {
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { showError, showToast } from "../constants/toast";
import { useEffect, useState } from "react";
import { usePostFaqMutation } from "../services/faq";
import { useLazyGetFaqQuery, useLazyDeleteFaqQuery } from "../services/faq";
import { FaqRes } from "../types/General";
import { generateEncryptedKeyBody } from "../utils/crypto";
import { CommonBody } from "../types/General";
import { usePutFaqMutation } from "../services/faq";
import Loader from "../constants/Loader";
import useAuth from "../hooks/useAuth";
import { Permissions } from "../types/User";
import { useNavigate } from "react-router-dom";

const MainFaq = () => {
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [faqData, setFaqData] = useState<FaqRes[]>([]);
  const navigate = useNavigate();
  const [postFaq] = usePostFaqMutation();
  const [getFaq, { isLoading }] = useLazyGetFaqQuery();
  const [deleteFaq] = useLazyDeleteFaqQuery();
  const [putFaq] = usePutFaqMutation();
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);


  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage CMS"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };



  useEffect(() => {
    checkPermission();
  }, [UserData])


  // Function to fetch all FAQs
  const handleGetAllFaq = async () => {
    try {
      const response = await getFaq({}).unwrap();
      if (response?.statusCode === 200) {
        setFaqData(response?.data?.data || []);
      }
    } catch (error) {
      showError("Error fetching FAQs");
    }
  };

  useEffect(() => {
    handleGetAllFaq();
  }, []);

  // postfaq
  const handleAddFaq = async () => {
    const body = {
      question: question,
      answer: answer,
      // appKey: new Date().toISOString(),
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
      const response = await postFaq(encryptedBody).unwrap();
      if (response?.statusCode === 200) {
        showToast("FAQ added successfully");
        // Add the new FAQ to the list
        setFaqData((prevFaqData: any) => [...prevFaqData, response?.data]);
        console.log(response.data, ";;;;;;;;;;;;;;");
        console.log()

        setQuestion("");
        setAnswer("");
      }
    } catch (error: any) {
      showError(error?.data?.message || "Failed to add FAQ");
    }
  };




  // Function to handle removing a FAQ
  const handleRemoveFaq = async (faqId: string) => {
    try {
      const response = await deleteFaq({ _id: faqId }).unwrap();
      if (response?.statusCode === 200) {
        showToast("FAQ removed successfully");
        // Remove the FAQ from the list
        setFaqData((prevFaqData) =>
          prevFaqData.filter((faq) => faq._id !== faqId)
        );
      }
    } catch (error: any) {
      showError(error?.data?.message || "Failed to remove FAQ");
    }
  };

  return (
    <Grid container spacing={2}>
      <Loader isLoad={isLoading} />
      {faqData.map((faq, index) => (
        <Grid key={index} item xs={12}>
          <Box className="faq_box">
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Question</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={faq.question}
                placeholder="Question"
              />
            </FormControl>
            <FormControl sx={{ width: "100%" }}>
              <Typography className="custom_label">Answer</Typography>
              <TextField
                hiddenLabel
                type="text"
                name="name"
                variant="outlined"
                value={faq.answer}
                placeholder="Answer"
              />
            </FormControl>
            <Box className="faq_btn">
              {hidePermission?.isEdit || UserData?.role === 1 ? (
                <Button
                  variant="contained"
                  className="btn btn_primary sm"
                  onClick={() => handleRemoveFaq(faq._id)}
                >
                  Remove
                </Button>
              ) : null}
            </Box>
          </Box>
        </Grid>
      ))}
      <Grid item xs={12}>
        <Box className="faq_box">
          <FormControl sx={{ mb: 2, width: "100%" }}>
            <Typography className="custom_label">Question</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={question}
              onChange={(event) => setQuestion(event.target.value)}
              placeholder="Question"
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography className="custom_label">Answer</Typography>
            <TextField
              hiddenLabel
              type="text"
              name="name"
              variant="outlined"
              value={answer}
              onChange={(event) => setAnswer(event.target.value)}
              placeholder="Answer"
            />
          </FormControl>
          <Box className="faq_btn">
            {hidePermission?.isEdit || UserData?.role === 1 ? (
              <Button
                variant="contained"
                disabled={!question || !answer}
                className="btn btn_primary sm"
                onClick={handleAddFaq}
              >
                Add
              </Button>
            ) : null}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default MainFaq;







