import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
  Input,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,

} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ChangeEvent, SetStateAction, useEffect, useRef, useState } from "react";
import { CommonBody, Discount, ProductData, TruckCategory, UserData, subCategoryType } from "../../types/General";
import { useLazyGetAllUsersQuery, useLazyGetAllUserswithoutPageQuery } from "../../services/users";
import { showError, showToast } from "../../constants/toast";
import { useLazyGetCouponByIdQuery, usePostCouponMutation, useUpdateCouponByIdMutation } from "../../services/coupon";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { useLazyGetCategoriesQuery, useLazyGetSubCategoriesQuery } from "../../services/categories";
import { useLazyGetProductsBySubCategoryIdQuery } from "../../services/products";
import Multiselect from 'multiselect-react-dropdown';
import { Console } from "console";

const AddDiscount = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [page, setPage] = useState<number>(1);

  const [totalCount, setTotalCount] = useState<number>(0);

  const [offerType, setOfferType] = useState<string>("");

  const [selectedCategory, setSelectedCategory] = useState<string>("");
  const [selectedSubCategory, setSelectedSubCategory] = useState<string>("");
  const [selectedProduct, setSelectedProduct] = useState<string>("");
  const [typeU, setTypeU] = useState<number>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [getAllProductsById] = useLazyGetProductsBySubCategoryIdQuery();
  const [productData, setProductData] = useState<ProductData[]>([]);
  const [addCoupon] = usePostCouponMutation();
  const [getAllCategories] = useLazyGetCategoriesQuery();
  const [getAllSubCategories] = useLazyGetSubCategoriesQuery();
  const [updateCoupon] = useUpdateCouponByIdMutation();
  const [getById] = useLazyGetCouponByIdQuery();
  const [coupon, setCoupon] = useState<Discount>();
  const [subData, setSubData] = useState<subCategoryType[]>([]);
  const [categoryData, setCategoryData] = useState<TruckCategory[]>([]);
  const [getAllWithoutPage] = useLazyGetAllUserswithoutPageQuery();
  const [usersDataForDiscount, setUserDataForDiscount] = useState<UserData[]>([]);

  const getCouponDetails = async (id: string | undefined) => {
    try {
      const res = await getById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setCoupon(res?.data);
        const preSelectedUserIds = res?.data?.userIdArray || [];
        setSelectedCategory(res?.data?.categoryId || "")
        setSelectedSubCategory(res?.data?.subCategoryId || "")
        setSelectedProduct(res?.data?.productId || "")
        setOfferType(String(res?.data?.discountType) || "")
        setSelectedUsers(preSelectedUserIds)
        setTypeU(res?.data?.type)
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  console.log(offerType, "offer type");



  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: coupon?.name || "",
      name_ar: coupon?.name_ar || "",
      discount: coupon?.discount || "",
      maximumDiscountAmount: coupon?.maximumDiscountAmount || "",
      minimumAmountToApply: coupon?.minimumAmountToApply || "",
      startDate: coupon?.startDate ? new Date(coupon.startDate).toISOString().slice(0, 16) : "",
      endDate: coupon?.endDate ? new Date(coupon.endDate).toISOString().slice(0, 16) : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),
      name_ar: Yup.string()
        .required("Name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed"),

      discount: Yup.number()
        .required("Discount is required"),
      maximumDiscountAmount: Yup.number()
        .required("This field is required"),
      minimumAmountToApply: Yup.number()
        .required("This field is required"),

      startDate: Yup.string()
        .required("Starting time is required")
        .test("is-valid-start-time", "Start time must be in the future", function (value) {
          const now = new Date();
          const startDate = new Date(value);
          return startDate > now;
        }),

      endDate: Yup.string()
        .required("Ending time is required")
        .test("is-valid-end-time", "End time must be after start time", function (value) {
          const startDate: any = new Date(formik.values.startDate);
          const endDate = new Date(value);
          return endDate > startDate;
        }),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        name: values.name,
        name_ar: values.name_ar,
        discount: values.discount,
        startDate: values.startDate,
        type: typeU,
        endDate: values.endDate,
        maximumDiscountAmount: values.maximumDiscountAmount,
        minimumAmountToApply: values.minimumAmountToApply,
        discountType: Number(offerType),
        categoryId: selectedCategory,
        subCategoryId: selectedSubCategory,
        productId: selectedProduct,
        userIdArray: typeU === 2 ? selectedUsers : [],
      };
      console.log(data, "data")


      if (!selectedCategory) {
        showError("Category is required")
        return;
      }

      if (!selectedSubCategory) {
        showError("Subcategory is required")
        return;
      }

      if (!offerType) {
        showError("Discount type is required")
        return;
      }
      if (typeU === 2 && selectedUsers.length === 0) {
        showError('Please select atleast one Product')
        return;
      }


      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateCoupon({ body: encryptedBody, id }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Coupon updated successfully!");
            navigate("/discount-and-coupon", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addCoupon(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Coupon added successfully!");
            navigate("/discount-and-coupon", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getCategory = async () => {
    try {
      const response = await getAllCategories({
      }).unwrap();
      if (response?.statusCode === 200) {
        setCategoryData(response?.data?.category || []);
      } else {
        setCategoryData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getSubCategory = async (id: string) => {
    try {
      const response = await getAllSubCategories({
        id: selectedCategory,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubData(response?.data?.subCategory || []);
      } else {
        setSubData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  const getProducts = async (id: string) => {
    try {
      const res = await getAllProductsById({
        page: page,
        size: 10,
        id: selectedSubCategory
      }).unwrap();
      if (res?.statusCode === 200) {
        setProductData(res?.data?.product || [])
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }



  const handleChangeCategory = (event: any) => {
    const value = event.target.value;
    setSelectedCategory(value);
  };
  const handleChangeSub = (event: any) => {
    const value = event.target.value;
    setSelectedSubCategory(value);
  };
  const handleChangeProduct = (event: any) => {
    const value = event.target.value;
    setSelectedProduct(value);
  };
  const handleOfferChange = (event: SelectChangeEvent) => {
    setOfferType(event.target.value);
  };


  //get all user api
  const getAllUsersFun2 = async () => {
    try {
      const response = await getAllWithoutPage({
      },).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setUserDataForDiscount(response?.data?.user);
      } else {
        setUserDataForDiscount([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  const formattedAmenities = [{ name: "All", value: "all" }, ...usersDataForDiscount?.map(user => ({
    value: user._id,
    name: user?.fullName ? (user?.fullName) : (user?.firstName + " " + user?.lastName),
  }))];

  const preSelectedValues = usersDataForDiscount
    .filter(item => selectedUsers.includes(item?._id))
    .map(item => ({
      value: item?._id,
      name: item?.fullName ? (item?.fullName) : (item?.firstName + " " + item?.lastName),
    }));
  if (typeU === 1) {
    preSelectedValues.unshift({ name: "All", value: "all" });
  }

  // Function to handle onSelect event
  const handleSelect = (selectedList: any, selectedItem: any) => {
    if (selectedItem.value === "all") {
      setSelectedUsers([]);
      setTypeU(1);
    } else {
      setSelectedUsers(selectedList?.map((option: { value: any; }) => option?.value))
      setTypeU(2);
    }
  };

  // Function to handle onRemove event
  const handleRemove = (selectedList: any, removedItem: any) => {
    if (removedItem.value === "all") {
      setTypeU(2);
      setSelectedUsers([]);
    } else {
      setSelectedUsers(selectedList?.map((option: { value: any; }) => option?.value))
      const isAllSelected = selectedList.some((item: { value: string; }) => item.value === "all");
      if (isAllSelected) {
        setTypeU(1);
      }
    }
  };



  useEffect(() => {
    getAllUsersFun2()
  }, [])

  useEffect(() => {
    if (id) {
      getCouponDetails(id);
    }
  }, []);

  useEffect(() => {
    getCategory();
  }, [])

  useEffect(() => {
    if (selectedCategory) {
      getSubCategory(selectedCategory)
    }
  }, [selectedCategory])

  useEffect(() => {
    if (selectedSubCategory) {
      getProducts(selectedSubCategory)
    }
  }, [selectedSubCategory])





  console.log(selectedUsers, "selectedUsers......");



  return (
    <>
      <MainContainer>
        <div className="main_loyout">
          <div className="dashboard">
            <h1>{id ? "Edit Discount Coupon" : "Add Discount Coupon"}</h1>
            <Button
              className="btn btn_primary"
              onClick={() => {
                navigate("/discount-and-coupon");
              }}
            >
              Back
            </Button>
          </div>
          <Card className="cards">
            <form onSubmit={formik.handleSubmit}
              onKeyPress={(event: any) => {
                if (event.key === 'Enter') {
                  event.preventDefault();
                }
              }}
            >
              <CardContent sx={{ p: 1 }}>
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Offer/Discount Name(English)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount name"
                      className="text_field"
                      id="name"
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name && formik.errors.name}
                      inputProps={{ maxLength: 30 }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Offer/Discount Name(Arabic)
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="name_ar"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount name"
                      className="text_field"
                      id="name_ar"
                      onBlur={formik.handleBlur}
                      value={formik.values.name_ar}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.name_ar && formik.errors.name_ar}
                      inputProps={{ maxLength: 30 }}
                    />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">Discount Type</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Select
                        className="select_div"
                        fullWidth
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        displayEmpty
                        value={offerType}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        onChange={handleOfferChange}
                      >
                        <MenuItem value="" disabled>Select</MenuItem>
                        <MenuItem value="1">Percentage</MenuItem>
                        <MenuItem value="2">Flat</MenuItem>
                        {/* <MenuItem value="3">Both</MenuItem> */}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Discount
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="discount"
                      id="discount"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount"
                      className="text_field"
                      inputProps={{ maxLength: offerType === '1' ? 2 : 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.discount}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.discount && formik.errors.discount}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Minimum amount to apply
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="minimumAmountToApply"
                      variant="outlined"
                      fullWidth
                      placeholder="Minimum amount to apply"
                      className="text_field"
                      id="minimumAmountToApply"
                      onBlur={formik.handleBlur}
                      value={formik.values.minimumAmountToApply}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.minimumAmountToApply && formik.errors.minimumAmountToApply}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Discount upto
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"text"}
                      name="maximumDiscountAmount"
                      variant="outlined"
                      fullWidth
                      placeholder="Discount upto"
                      className="text_field"
                      id="maximumDiscountAmount"
                      onBlur={formik.handleBlur}
                      value={formik.values.maximumDiscountAmount}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.maximumDiscountAmount && formik.errors.maximumDiscountAmount}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Product category
                      </Typography>{" "}
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        displayEmpty
                        value={selectedCategory}
                        onChange={handleChangeCategory}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {categoryData?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Product Sub-category
                      </Typography>{" "}
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        value={selectedSubCategory}
                        disabled={subData.length === 0 ? true : false}
                        onChange={handleChangeSub}
                        displayEmpty
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {subData?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <FormControl fullWidth>
                      <Typography className="custom_label">
                        Product Name
                      </Typography>{" "}
                      <Select
                        className="select_div "
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={selectedProduct}
                        displayEmpty
                        disabled={productData.length === 0 ? true : false}
                        onChange={handleChangeProduct}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {productData?.map((item, index) => (
                          <MenuItem value={item?._id}>{item?.name}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      Customer Name
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <Multiselect
                        className='users_autocomplete'
                        options={formattedAmenities}
                        displayValue="name"
                        onSelect={handleSelect}
                        onRemove={handleRemove}
                        selectedValues={id ? (preSelectedValues) : []}
                      />
                    </FormControl>
                  </Grid>


                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label">
                      {" "}
                      Start date and time
                    </Typography>
                    <TextField
                      hiddenLabel
                      type={"datetime-local"}
                      name="startDate"
                      variant="outlined"
                      fullWidth
                      placeholder="Start Time"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.startDate}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.startDate && formik.errors.startDate}
                    />
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={12}>
                    <Typography className="custom_label"> End date and time</Typography>
                    <TextField
                      hiddenLabel
                      type={"datetime-local"}
                      name="endDate"
                      variant="outlined"
                      fullWidth
                      placeholder="End Time"
                      className="text_field"
                      inputProps={{ maxLength: 30 }}
                      onBlur={formik.handleBlur}
                      value={formik.values.endDate}
                      onChange={(val) => {
                        formik.handleChange(val);
                      }}
                      helperText={formik.touched.endDate && formik.errors.endDate}
                    />
                  </Grid>

                </Grid>

                <div className="form_btn">
                  <Button
                    size="large"
                    type="submit"
                    className="btn btn_primary"
                  >
                    Save
                  </Button>
                </div>
              </CardContent>
            </form>
          </Card>
        </div>
      </MainContainer>
    </>
  );
};

export default AddDiscount;
