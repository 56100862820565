import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { BannerType, CommonBody } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetTokenParams = {
    page?: number;
    query?: string;
};
type AllBannersType = {
    count: number,
    page: number,
    banner: BannerType[]
}


export const BannerApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postBanner: builder.mutation<
            CommonResponseType & { data: BannerType },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.banners,
                method: "POST",
                body
            })
        }),

        getAllBanners: builder.query<
            CommonResponseType & { data: AllBannersType },
            GetTokenParams
        >({
            query: ({ query, page }) => ({
                url: `${END_POINTS.banners}?page=${page}&search=${query}`,
                method: "GET"
            })
        }),

        getBannerById: builder.query<
            CommonResponseType & { data: BannerType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.banners}/${id}`,
                method: "GET",
            })
        }),

        updateBannerById: builder.mutation<
            CommonResponseType & { data: BannerType },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.banners}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteBanner: builder.mutation<
            CommonResponseType & { data: BannerType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.banners}/${id}`,
                method: "DELETE"
            })
        }),
    })
})

export const {
    usePostBannerMutation,
    useLazyGetAllBannersQuery,
    useLazyGetBannerByIdQuery,
    useUpdateBannerByIdMutation,
    useDeleteBannerMutation
} = BannerApi;