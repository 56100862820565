import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Autocomplete, Polygon } from "@react-google-maps/api";
import { useNavigate, useParams } from "react-router-dom";
import { DrawingManager, GoogleMap } from "@react-google-maps/api";
import * as Yup from "yup";
import { useFormik } from "formik";
import { GeofenceRequest, CommonBody, GeofenceData } from "../../types/General";
import {
  useAddGeofenceMutation,
  useLazyGetGeofenceByIdQuery,
  useUpdateGeofenceMutation,
} from "../../services/geofence";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import MainContainer from "../../layout/MainContainer";
import { GOOGLE_API_KEY } from "../../constants/url";
import { useAppSelector } from "../../hooks/store";
import { getToken } from "../../reducers/authSlice";

interface LatLng {
  lat: number;
  lng: number;
}

interface CoordinateArray extends Array<[number, number]> { }



declare global {
  interface Window {
    google: typeof google;
  }
}

let temp: any = [];
const AddGeofence: React.FC = () => {
  const [displayMap, setDisplayMap] = useState(false);
  const [initialPoints, setInitalPoints] = useState<any>([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [addGeofence] = useAddGeofenceMutation();
  const [geofenceById] = useLazyGetGeofenceByIdQuery();
  const [updateGeofence] = useUpdateGeofenceMutation();
  const [geofenceData, setGeofenceData] = useState<GeofenceData>();
  const [points, setPoints] = useState<CoordinateArray[]>();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [googleMapsLoaded, setGoogleMapsLoaded] = useState<boolean>(false);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const listenersRef = useRef<google.maps.MapsEventListener[]>([]);
  const map = useRef<GoogleMap | null>(null);
  const [autocomplete, setAutocomplete] = useState(null);
  const [lat1, setLat1] = useState<number>(0);
  const [lng1, setLng1] = useState<number>(0);

  const marks: LatLng[] = [
    {
      lng: lng1,
      lat: lat1,
    },
  ];
  const [polygonPaths, setPolygonPaths] = useState<any>()
  const getGeofenceDetails = async (id: string | undefined) => {
    setDisplayMap(false)
    try {
      const res = await geofenceById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setGeofenceData(res?.data);
        const coordinates: CoordinateArray = res?.data?.geoFence.coordinates[0] as unknown as CoordinateArray;
        console.log(coordinates, "coordinates");
        const latLngs: LatLng[] = coordinates.map((coord) => ({
          lat: coord[1],
          lng: coord[0],
        }));
        const temp2: any = [];
        coordinates?.map((data) => {
          temp2.push(
            { lat: data?.[1], lng: data?.[0] },
          );
        })

        setPoints([coordinates]);
        setInitalPoints(temp2);
        temp = temp2;
        setLat1(latLngs[0].lat);
        setLng1(latLngs[0].lng);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
    setDisplayMap(true)
  };

  useLayoutEffect(() => {
    if (id) {
      getGeofenceDetails(id);
    }
  }, []);

  const onLoad = (drawingManager: google.maps.drawing.DrawingManager) => {
    console.log(drawingManager, "");
    drawingManager.setDrawingMode(window.google.maps.drawing.OverlayType.POLYGON);
    const polygon = new window.google.maps.Polygon({
      paths: temp,
      fillColor: "#1E90FF",
      strokeColor: "#1E90FF",
      editable: true,
      draggable: true,

    });
    polygon.setMap(drawingManager.getMap());
    window.google.maps.event.addListener(polygon, "rightclick", () => {
      polygon.setMap(null);
    });
    setPolygonPaths(temp);

  };



  const onLoadG = (autocompleteObj: any) => {
    setAutocomplete(autocompleteObj);

  };

  const containerStyle: React.CSSProperties = {
    width: "800px",
    height: "400px",
  };

  const onPolygonComplete = (polygon: google.maps.Polygon) => {
    const res = polygon.getPath().getArray();
    const latLangs: LatLng[] = res.map((item) => ({
      lat: item.lat(),
      lng: item.lng(),
    }));
    latLangs.push(latLangs[0]);
    const coordinates: CoordinateArray = latLangs.map(({ lat, lng }) => [lng, lat]);

    setPoints([coordinates]);

  };

  console.log(points, "points..............");

  const onPlaceChanged = async () => {
    if (autocomplete) {
      let place = await (autocomplete as any).getPlace()
      if (place && place.address_components) {
        let address = place.address_components;
        let state,
          city,
          country,
          zip = "";
        address.forEach(function (component: any) {
          let types = component.types;
          if (types.indexOf("locality") > -1 || types.indexOf("administrative_area_level_3") > -1) {
            city = component.long_name;
          }
          if (types.indexOf("postal_code") > -1) {
            zip = component.long_name;
          }
          if (types.indexOf("administrative_area_level_1") > -1) {
            state = component?.long_name || "";
          }
          if (types.indexOf("country") > -1) {
            country = component?.long_name || "";
          }
        });

        var lat = place.geometry.location.lat();
        var lng = place.geometry.location.lng();
        setLat1(lat)
        setLng1(lng)


        formik.setFieldValue("address", `${place?.formatted_address}`);
        formik.setFieldValue("city", city || "");
        formik.setFieldValue("country", country || "");
        formik.setFieldValue("state", state || "");
        formik.setFieldValue("zipCode", zip || "");
        formik.setFieldValue("lat", lat || "");
        formik.setFieldValue("lng", lng || "");
      }
    }
  };

  const formik = useFormik({

    enableReinitialize: true,
    initialValues: {
      geofenceName: geofenceData?.geofenceName,
      geofenceName_ar: geofenceData?.geofenceName_ar,
      // coordinates: geofenceData?.geoFence
      geoFence: {
        type: "polygon",
        coordinates: points
      }
    },
    validationSchema: Yup.object({
      geofenceName: Yup.string()
        .required("Geofence name is required")
        .max(80, "Maximum 80 characters are allowed"),
      geofenceName_ar: Yup.string()
        .required("Geofence name is required")
        .max(80, "Maximum 80 characters are allowed"),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);

      let data = {
        geofenceName: values.geofenceName,
        geofenceName_ar: values.geofenceName_ar,
        geoFence: {
          type: "Polygon",
          coordinates: points
        }
      };
      console.log(data, "payload....");



      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateGeofence({ body: encryptedBody, id: id }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Geofence updated successfully!");
            navigate("/manage-geofence", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addGeofence(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Geofence created successfully!");
            navigate("/manage-geofence", { replace: true });
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const getCurrentPosition = () => {
    navigator.geolocation.getCurrentPosition((position) => {
      setLat1(position.coords.latitude);
      setLng1(position.coords.longitude);
    });
  };

  useEffect(() => {
    let googleMapsScript: HTMLScriptElement;

    const loadGoogleMaps = async () => {
      googleMapsScript = document.createElement("script");
      googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=drawing,places`;
      googleMapsScript.async = true;
      await new Promise((resolve, reject) => {
        googleMapsScript.onload = resolve;
        googleMapsScript.onerror = reject;
        document.body.appendChild(googleMapsScript);
      });
      setGoogleMapsLoaded(true);
    };

    loadGoogleMaps();

    return () => {
      if (googleMapsScript && googleMapsScript.parentNode === document.body) {
        document.body.removeChild(googleMapsScript);
      }
    };
  }, []);


  useEffect(() => {
    getCurrentPosition();
  }, []);

  useEffect(() => {
    if (id) {
      setDisplayMap(false)
    } else {
      setDisplayMap(true)
    }
  }, []);


  if (!googleMapsLoaded) {
    return <div>Loading Google Maps...</div>;
  }



  return (
    <MainContainer>
      <div className="main_layout">
        <div className="dashboard" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <h1>{id ? "Edit Geofence" : "Add Geofence"}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-geofence");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Geofence name (English)<span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="geofenceName"
                    variant="outlined"
                    fullWidth
                    placeholder="Geofence Name"
                    className="text_field"
                    inputProps={{ maxLength: 30 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.geofenceName}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent form submission
                      }
                    }}
                    helperText={formik.touched.geofenceName && formik.errors.geofenceName}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Geofence name (Arabic)<span className="asterisk">*</span>
                  </Typography>
                  <TextField
                    hiddenLabel
                    type={"text"}
                    name="geofenceName_ar"
                    variant="outlined"
                    fullWidth
                    placeholder="Geofence Name"
                    className="text_field"
                    inputProps={{ maxLength: 30 }}
                    onBlur={formik.handleBlur}
                    value={formik.values.geofenceName_ar}
                    onChange={(val) => {
                      formik.handleChange(val);
                    }}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        event.preventDefault(); // Prevent form submission
                      }
                    }}
                    helperText={formik.touched.geofenceName_ar && formik.errors.geofenceName_ar}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">
                    Search Geofence
                  </Typography>
                  <Autocomplete
                    onLoad={onLoadG}
                    onPlaceChanged={() => onPlaceChanged()}
                  >
                    <TextField
                      hiddenLabel
                      type="search"
                      name="search"
                      variant="outlined"
                      fullWidth
                      placeholder="Search Geofence"
                      // value={formik.values.coordinates}
                      onChange={formik.handleChange}
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          event.preventDefault(); // Prevent form submission
                        }
                      }}
                    />
                  </Autocomplete>
                </Grid>
                {/* <Grid item lg={4} md={4} sm={6} xs={12} /> */}
                {displayMap && <Grid item lg={8} md={8} sm={8} xs={12}>
                  <GoogleMap
                    mapContainerStyle={containerStyle}
                    center={marks[0]}
                    zoom={10}
                  >
                    <DrawingManager

                      onLoad={onLoad}

                      onPolygonComplete={onPolygonComplete}
                      drawingMode={window.google.maps.drawing.OverlayType.POLYGON}
                      options={{
                        drawingControl: false,
                        markerOptions: {
                          // editable: true,
                        },
                        polygonOptions: {
                          fillColor: "#1E90FF",
                          strokeColor: "#1E90FF",
                          editable: true,
                          draggable: true,
                        },
                        polylineOptions: {
                          strokeColor: "#FF273A",
                        },
                      }}
                    />
                    <Polygon
                      paths={polygonPaths}
                      options={{
                        fillColor: "#1E90FF",
                        strokeColor: "#1E90FF",
                        editable: true,
                        draggable: true,
                      }}
                    />
                  </GoogleMap>
                </Grid>}
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddGeofence;