import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, GeofenceData, GeofenceRequest, GeofenceResponse, PaymentType } from "../types/General";
import AddNotification from "../pages/manageNotification/add";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    size?: number;
    query?: string;
    fromDate?:string;
    toDate?:string;
};


type responcePayment = {
    data: PaymentType[];
    count?: number;
    pages?: number;
    totalEarning?: number;
}

export const paymentApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getPayments: builder.query<
            CommonResponseType & {data:responcePayment} ,
            GetTokenParams
        >({
            query: ({ query, page, size, fromDate, toDate }) => ({
                url: `${END_POINTS.payments}?page=${page}&size=${size}&search=${query}&fromDate=${fromDate}&toDate=${toDate}`,
                method: "GET",
            })
        }),

        getPaymentById: builder.query<
            CommonResponseType & { data: PaymentType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.payments}/${id}`,
                method: "GET",
            }),
        }),

    })
})

export const {
    useLazyGetPaymentsQuery,
    useLazyGetPaymentByIdQuery,

} = paymentApi;