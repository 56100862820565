import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, subAdminRole, subAdminType } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    size?: number;
    query?: string;
};

type GetAllSubadminsResponse = {
    pages: number;
    count: number;
    subAdmin: subAdminType[];
};

type getAllRoles = {
    pages: number;
    count: number;
    data: subAdminRole[];
}

export const subAdminApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        addSubAdmin: builder.mutation<
            CommonResponseType & { data: subAdminType },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.subAdmin,
                method: "POST",
                body,
            })
        }),

        getSubAdmin: builder.query<
            CommonResponseType & { data: GetAllSubadminsResponse },
            GetTokenParams
        >({
            query: ({ query, page, size }) => ({
                url: `${END_POINTS.subAdmin}?page=${page}&search=${query}&size=${size}`,
                method: "GET",
            })
        }),

        getSubAdminById: builder.query<
            CommonResponseType & { data: subAdminType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "GET",
            }),
        }),

        updateSubAdmin: builder.mutation<
            CommonResponseType & { data: subAdminType },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "PUT",
                body
            }),
        }),

        deleteSubAdmin: builder.mutation<
            CommonResponseType & { data: subAdminType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subAdmin}/${id}`,
                method: "DELETE",
            }),
        }),

        //roles
        addSubAdminRole: builder.mutation<
            CommonResponseType & { data: subAdminRole },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.subAdminRole,
                method: "POST",
                body,
            })
        }),

        getSubAdminRole: builder.query<
            CommonResponseType & { data: getAllRoles },
            GetTokenParams
        >({
            query: ({ query, page, size }) => ({
                url: `${END_POINTS.subAdminRole}?page=${page}&search=${query}&size=${size}`,
                method: "GET",
            })
        }),
        updateSubAdminRole: builder.mutation<
            CommonResponseType & { data: subAdminRole },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.subAdminRole}/${id}`,
                method: "PUT",
                body
            }),
        }),

        deleteSubAdminRole: builder.mutation<
            CommonResponseType & { data: subAdminRole },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subAdminRole}/${id}`,
                method: "DELETE",
            }),
        }),
    })
})

export const {
    useAddSubAdminMutation,
    useLazyGetSubAdminQuery,
    useLazyGetSubAdminByIdQuery,
    useUpdateSubAdminMutation,
    useDeleteSubAdminMutation,

    //subAdmin role
    useAddSubAdminRoleMutation,
    useLazyGetSubAdminRoleQuery,
    useUpdateSubAdminRoleMutation,
    useDeleteSubAdminRoleMutation

} = subAdminApi;