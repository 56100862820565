import { useNavigate } from "react-router-dom";
import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";

const ProductDetails = () => {
    const navigate = useNavigate();

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    const label = { inputProps: { "aria-label": "Switch demo" } };

    return (
        <MainContainer>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">View Product  Details</h1>
                    <Button
                        className="btn btn_primary"
                        onClick={() => {
                            navigate("/inventory-management");
                        }}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <CardContent sx={{ p: 1 }}>
                        <Grid container spacing={2} className="view_box">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    className="detail_title mn_hdng"
                                    component="h2"
                                    mb={3}
                                >
                                    Product Details
                                </Typography>
                            </Grid>
                            
                            <Grid item xs={12} className="view_box_list">
                                <Grid container spacing={3}>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Product Name</Typography>
                                            <Typography component="p">GearBox</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product ID</Typography>
                                            <Typography component="p">01</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Product category</Typography>
                                            <Typography component="p">Car</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Product Cost</Typography>
                                            <Typography component="p">1000</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Product quantity</Typography>
                                            <Typography component="p">2</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product quantity limit for purchase
                                            </Typography>
                                            <Typography component="p">10</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Brand</Typography>
                                            <Typography component="p">Tata</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Size</Typography>
                                            <Typography component="p"> 10 inch </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Type</Typography>
                                            <Typography component="p">Automatic </Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product Colour</Typography>
                                            <Typography component="p"> Grey</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Discount</Typography>
                                            <Typography component="p"> 5%</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Expiry date</Typography>
                                            <Typography component="p"> 00/00/0000</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={12} md={12} sm={12} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Product description</Typography>
                                            <Typography component="p"> Lorem ipsum dolor sit amet consectetur, adipisicing elit
                                                dolor sit amet consectetur.</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </MainContainer>
    );
};

export default ProductDetails;
