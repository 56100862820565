import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  CardMedia,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Input,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import * as Yup from "yup";
import CancelIcon from "@mui/icons-material/Cancel";
import { isString, useFormik } from "formik";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDeleteSubCategoryMutation, useEditSubCategoryMutation, useLazyGetAllSubCategoriesQuery, useLazyGetSubCategoryByIdQuery, usePostSubCategoryMutation } from "../../services/categories";
import { CommonBody, subCategoryType } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { updateStatus } from "../../utils/commonFunctions";
import WarnModal from "../../components/WarnModal";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { UploadMedia } from "../../utils/mediaUpload";
import Loader from "../../constants/Loader";


const SubCategories = () => {
  const [image, setImage] = useState<string>("");
  const [fileName, setFileName] = useState<string>("");
  const [subId, setSubId] = useState<string>("");
  const { id } = useParams();
  const navigate = useNavigate();
  // const [name, setName] = useState<string>("");
  const [isAdd, setIsAdd] = useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [subCategory, setSubCategory] = useState<subCategoryType>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [getAllSubCatergories, { isLoading }] = useLazyGetAllSubCategoriesQuery();
  const [updateSubCategory] = useEditSubCategoryMutation();
  const [deleteSubCategory] = useDeleteSubCategoryMutation();
  const [getById] = useLazyGetSubCategoryByIdQuery();
  const [addSubCategory] = usePostSubCategoryMutation();
  const [subData, setSubData] = useState<subCategoryType[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [page, setPage] = useState(1);
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [selectedId, setSelectedId] = useState("");
  const location = useLocation();
  const { state } = location;

  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const files = event.target;
    const file = files?.files?.length ? files?.files[0] : "";
    const res = await UploadMedia(file);
    if (res?.statusCode === 200) {
      setImage(res?.data);
    } else {
      showError(res?.message);
    }
  };



  const handleClose = () => {
    setOpenDialog(false);
    setSelectedId("");
    formik.resetForm();
  };

  const handleEdit = async (id: string) => {
    setOpenDialog(true);
    setSubId(id);
    try {
      const response = await getById({ id }).unwrap();
      if (response?.statusCode === 200) {
        setSubCategory(response?.data);
        // Set image state with the existing image URL
        setImage(response?.data?.image || "");
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };


  // const handleEdit = (id: string) => {
  //   setOpenDialog(true);
  //   setSubId(id);
  //   fetchById(id);
  // }

  // const fetchById = async (id: string) => {
  //   try {
  //     const response = await getById({ id }).unwrap();
  //     if (response?.statusCode === 200) {
  //       setSubCategory(response?.data);
  //       console.log(subCategory, "subCtegory");
  //     }
  //   } catch (error: any) {
  //     console.log(error, "errror");
  //     showError(error?.data?.message || "");
  //   }
  // };

  let totalPages = Math.ceil(totalCount / 10);

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const getSubCategory = async () => {
    try {
      const response = await getAllSubCatergories({
        id: id,
        query: debouncedSearchTerm.trim(),
        page: page,
      }).unwrap();
      if (response?.statusCode === 200) {
        setSubData(response?.data?.subCategory || []);
        setTotalCount(response?.data?.count);
        // setImage(response?.data?.subCategory[0]?.image)
      } else {
        setSubData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };


  useEffect(() => {
    // if (subId) {
    //   fetchById(subId);
    // }
    getSubCategory();
  },
    [debouncedSearchTerm, page]
  );

  //delete 
  const handleDeleteCategory = async (id: string) => {
    try {
      const res = await deleteSubCategory({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Sub-category deleted successfully");
        await getSubCategory();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  const updateUserStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateSubCategory({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Sub-category status updated successfully");
        await getSubCategory();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating sub-category status");
    }
  };
  console.log(subId, "subId");


  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      // name: subCategory?.name || "",
      name: isAdd ? "" : subCategory?.name || "",
      name_ar: isAdd ? "" : subCategory?.name_ar || "",
      // image: image || "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Sub-category name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(
          /^\S/,
          "Category name cannot start with a blank space"
        ),
      name_ar: Yup.string()
        .required("Sub-category name is required")
        .min(2, "Minimum 2 characters are required")
        .max(80, "Maximum 80 characters are allowed")
        .matches(
          /^\S/,
          "Category name cannot start with a blank space"
        ),
    }),

    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let data = {
        name: values.name,
        name_ar: values.name_ar,
        image: image,
        parent: id
      };
      console.log(data, "subcategory body")

      if (!image) {
        showError("Please add a image");
        return;
      }

      if (isAdd) {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await addSubCategory(encryptedBody).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-Category added successfully!");
            setOpenDialog(false);
            await getSubCategory();
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
          const response = await updateSubCategory({ body: encryptedBody, id: subId }).unwrap();
          if (response?.statusCode === 200) {
            showToast("Sub-Category updated successfully!");
            setOpenDialog(false);
            await getSubCategory();
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    }
  }
  );


  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1>View Sub-Categories</h1>
        </div>
        <Card className="cards">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h4 style={{ margin: 0 }}>{state}</h4>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Button
                sx={{ marginRight: "10px" }}
                className="btn btn_primary"
                onClick={() => { setOpenDialog(true); setIsAdd(true); setImage(""); }}
              >
                Add Sub-category
              </Button>
              <Button
                className="btn btn_primary"
                onClick={() => navigate("/manage-categories")}
              >
                Back
              </Button>
            </Box>
          </Box>

          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell>Image</TableCell>
                  <TableCell>Sub-Category Name</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              {
                subData?.length ? (
                  subData.map((row: subCategoryType, i: number) => (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">{i + 1}</TableCell>
                        <TableCell>
                          <figure className="user_img">
                            <img
                              src={row?.image || ""}
                              alt=""
                            />
                          </figure>
                        </TableCell>
                        <TableCell>{row?.name}</TableCell>
                        <TableCell>
                          <Switch
                            {...label}
                            size="small"
                            checked={!row?.isBlocked}
                            onChange={() =>
                              updateUserStatus(row?._id, !row?.isBlocked)
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box className="table_actions">
                            <IconButton
                            // onClick={() => { setOpenDialog(true); setIsAdd(false); setSubId(row?._id) }}
                            >
                              <ModeEditIcon
                                onClick={() => { handleEdit(row?._id); setIsAdd(false); }} />
                            </IconButton>
                            <IconButton >
                              <DeleteIcon onClick={() => {
                                setOpen(true);
                                setSelectedId(row?._id);
                              }} />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Sub-Category Found
                    </TableCell>
                  </TableBody>
                )
              }

            </Table>
          </TableContainer>
        </Card>
      </div >
      <Dialog open={openDialog} onClose={handleClose} className="revert_dialog">
        <DialogContent>
          <Typography className="dialog_title">{!isAdd ? "Edit Sub-category" : "Add Sub-category"}</Typography>
          <form onSubmit={formik.handleSubmit}>
            <FormControl fullWidth>
              {/* <Typography className="custom_label">Image</Typography> */}
              {image ? (
                <div className="upload_image_preview" >
                  <CardMedia component="img" image={image} alt="photo" />
                  <CancelIcon
                    onClick={() => {
                      setImage("");
                    }}
                  />
                </div>
              ) : (
                <Box className="upload_image" style={{ margin: "0 auto" }}>
                  <label htmlFor="icon-button-file">
                    <Input
                      sx={{ display: "none" }}
                      id="icon-button-file"
                      type="file"
                      inputProps={{
                        accept: "image/png,image/jpeg",
                      }}
                      onChange={(
                        e: React.ChangeEvent<HTMLInputElement>
                      ) => handleImageUpload(e)}
                    />
                    <Button component="span" className="upload_image_btn">
                      <img
                        src="/static/images/user_placeholder.png"
                        alt=""
                      />
                      <CameraAltIcon />
                    </Button>
                  </label>
                </Box>
              )}
              <TextField
                sx={{ marginTop: 2 }}
                hiddenLabel
                className="text_field"
                autoFocus
                id="name"
                type={"text"}
                name="name"
                placeholder="Sub-category Name (English)"
                fullWidth
                inputProps={{ maxLength: 50 }}
                value={formik.values.name}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                sx={{ marginTop: 2 }}
                hiddenLabel
                className="text_field"
                autoFocus
                id="name_ar"
                type={"text"}
                name="name_ar"
                placeholder="Sub-category Name (Arabic)"
                fullWidth
                inputProps={{ maxLength: 50 }}
                value={formik.values.name_ar}
                onChange={(val) => {
                  if (
                    val.target.value === " " ||
                    val.target.value === "."
                  ) {
                  } else if (isString(val.target.value)) {
                    formik.handleChange(val);
                  }
                }}
                helperText={formik.touched.name_ar && formik.errors.name_ar}
              />
            </FormControl>
            <Box className="form_btn">
              <Button className="btn btn_primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button className="btn btn_primary" type="submit">{!isAdd ? "Save" : "Add"}</Button>
            </Box>
          </form>
        </DialogContent>
      </Dialog>
      <WarnModal
        name="Sub-category"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteCategory(selectedId)}
      />
    </MainContainer >
  );
};

export default SubCategories;
