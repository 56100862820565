import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import AddLocationAltIcon from "@mui/icons-material/AddLocationAlt";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import PaidIcon from "@mui/icons-material/Paid";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ViewCarouselIcon from "@mui/icons-material/ViewCarousel";
import DiscountIcon from "@mui/icons-material/Discount";
import SummarizeTwoToneIcon from "@mui/icons-material/SummarizeTwoTone";

import {
  Box,
  Button,
  Checkbox,
  Container,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import { setCredentials } from "../../reducers/authSlice";
import { useAppDispatch } from "../../hooks/store";
import { usePostLoginMutation } from "../../services/auth";
import "./Login.scss";
import { getFromStorage, removeFromStorage, setToStorage } from "../../constants/storage";
import { STORAGE_KEYS } from "../../constants/storageKeys";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody } from "../../types/General";


const Login = () => {

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const [loginMutation, { isLoading }] = usePostLoginMutation();

  const items = [
    {
      label: "Dashboard",
      icon: <DashboardIcon />,
      key: "1",
      selected: "/dashboard",
    },
    {
      label: "Manage Customers",
      icon: <PeopleIcon />,
      key: "2",
      selected: "/manage-customers",
    },
    {
      label: "Manage Categories",
      icon: <ListAltIcon />,
      key: "3",
      selected: "/manage-categories",
    },
    {
      label: "Manage Product Specifications",
      icon: <ContentPasteIcon />,
      key: "4",
      selected: "/manage-products",
    },
    {
      label: "Inventory Management",
      icon: <SubscriptionsIcon />,
      key: "5",
      selected: "/inventory-management",
    },
    {
      label: "Product Order Management",
      icon: <ProductionQuantityLimitsIcon />,
      key: "6",
      selected: "/product-order-management",
    },
    {
      label: "Manage Bulk Orders",
      icon: <ProductionQuantityLimitsIcon />,
      key: "7",
      selected: "/manage-bulk-orders",
    },

    {
      label: "Manage Payments",
      icon: <PaidIcon />,
      key: "8",
      selected: "/manage-revenue",
    },

    {
      label: "Manage Sub-Admin",
      icon: <PersonAddIcon />,
      key: "9",
      selected: "/manage-subAdmin",
    },
    {
      label: "Reports and Analytics",
      icon: <BarChartIcon />,
      key: "10",
      selected: "/analytics",
    },
    {
      label: "Discount and Coupon Management",
      icon: <DiscountIcon />,
      key: "11",
      selected: "/discount-and-coupon",
    },
    {
      label: "Manage Geoforce",
      icon: <AddLocationAltIcon />,
      key: "12",
      selected: "/manage-geofence",
    },
    {
      label: "Manage Banners",
      icon: <ViewCarouselIcon />,
      key: "13",
      selected: "/ad-banner-management",
    },
    {
      label: "Manage Notifications",
      icon: <NotificationsActiveIcon />,
      key: "14",
      selected: "/manage-notifications",
    },
    {
      label: "Manage Factory Tour",
      icon: <ViewCarouselIcon />,
      key: "15",
      selected: "/manage-factory-tour",
    },
    {
      label: "Manage CMS",
      icon: <SummarizeTwoToneIcon />,
      key: "16",
      selected: "/cms",
    },
  ];

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
    const inputEl: HTMLInputElement | null = document.getElementById(
      "password"
    ) as HTMLInputElement | null;
    if (inputEl) {
      setTimeout(() => {
        inputEl.setSelectionRange(inputEl.value.length, inputEl.value.length);
      }, 0);
    }
  };

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: false,
    },
    validationSchema: Yup.object({
      remember: Yup.boolean(),
      email: Yup.string()
        .required("Email is required!")
        .matches(
          /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
          "Enter a valid email address"
        ),
      password: Yup.string()
        .matches(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/,
          "Enter a strong password")
        .required("Password is required")
        .min(6, "Password must be at least 6 characters"),
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      const fcmtoken = getFromStorage(STORAGE_KEYS.fcmToken);
      let data = {
        key: formik.values.email,
        password: formik.values.password,
        deviceType: "WEB",
        deviceToken: fcmtoken || "1234"
      };
      console.log(data, "data..");

      try {
        let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
        const response = await loginMutation(encryptedBody).unwrap();
        if (response?.statusCode === 200) {
          showToast(response?.message || "Login Successfully.");
          setToStorage(STORAGE_KEYS.token, response?.data?.token || "");
          dispatch(
            setCredentials({
              user: response?.data,
              token: response?.data?.token || "",
            })
          );

          if (formik.values.remember) {
            setToStorage(STORAGE_KEYS.credentials, JSON.stringify(data));
          } else {
            removeFromStorage(STORAGE_KEYS.credentials);
          }

          if (response?.data?.role === 2) {
            const permissions = response?.data?.permissions;
            if (response?.data?.role === 2 && permissions?.length > 0) {
              const firstPermissionLabel = permissions[0]?.label;
              const redirectPath = items.find(
                (item) => item.label === firstPermissionLabel
              )?.selected;

              if (redirectPath) {
                navigate(redirectPath);
              } else {
                console.error("Invalid permission label for redirection");
              }
            }
          } else {
            navigate("/dashboard");
          }
          // navigate("/dashboard");
        }
      } catch (error: any) {
        showError(error?.data?.message || "");
        console.log(error);
      }
    },
  });
  useEffect(() => {
    const data = getFromStorage(STORAGE_KEYS.credentials);
    if (data) {
      const rememberData = JSON.parse(`${data}`);
      formik.setFieldValue("password", rememberData?.password);
      formik.setFieldValue("email", rememberData?.key);
      formik.setFieldValue("remember", true);
    }
    // eslint-disable-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box
      component="main"
      className="auth_module"
      sx={{
        alignItems: "center",
        display: "flex",
        flexGrow: 1,
      }}
    >
      {/* <Loader isLoad={isLoading} /> */}
      <Container className="auth_cntnr" maxWidth="xs">
        <Box
          m={0}
          className="auth_logo"
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <figure className="logo_cntnr">
            <img src="/static/images/logo.png" alt="" />
          </figure>
        </Box>
        <div className="auth_box">
          <Box sx={{ mb: 3, textAlign: "center", color: "#1d1d1d" }}>
            <Typography variant="h5">Log in to HQ Admin</Typography>
          </Box>
          <form onSubmit={formik.handleSubmit}>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Email Address</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                placeholder="Enter Email Address"
                fullWidth
                name="email"
                type="email"
                id="email"
                variant="outlined"
                onChange={(val) => {
                  if (val.target.value === " " || val.target.value === ".") {
                  } else {
                    formik.handleChange(val);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FormControl>
            <FormControl sx={{ mb: 2, width: "100%" }}>
              <Typography className="custom_label">Password</Typography>
              <TextField
                className="text_field"
                hiddenLabel
                fullWidth
                placeholder="Enter Password"
                name="password"
                id="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                helperText={formik.touched.password && formik.errors.password}
                type={showPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment className="eye_btn" position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </FormControl>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box className="remember_box">
                <FormControlLabel
                  control={<Checkbox />}
                  label="Remember me"
                  checked={formik.values.remember}
                  name="remember"
                  onChange={formik.handleChange}
                />
              </Box>
              <Box
                className="anchor_link"
                onClick={() => navigate("/forgotpassword")}
              >
                <Typography>Forgot Password?</Typography>
              </Box>
            </Box>
            <Box sx={{ pt: 3 }}>
              <Button
                className="btn btn_primary"
                color="primary"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Submit
              </Button>
            </Box>
          </form>
        </div>
      </Container>
    </Box>
  );
};

export default Login;
