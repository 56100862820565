import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyAzSZmQx-jC6GGZUBiAnKL7LoS7GApOXiM",
  authDomain: "hqapp-8df66.firebaseapp.com",
  projectId: "hqapp-8df66",
  storageBucket: "hqapp-8df66.appspot.com",
  messagingSenderId: "619966459228",
  appId: "1:619966459228:web:e3410e67ae930dba50e599",
  measurementId: "G-12NS8MCW6S"
};
const app = initializeApp(firebaseConfig);
export default app;
