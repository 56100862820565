import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, FactoryTour } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type GetTokenParams = {
    page?: number;
    query?: string;
};
type AllFactoryType = {
    count: number,
    page: number,
    data: FactoryTour[]
}


export const FactoryApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postFactoryTour: builder.mutation<
            CommonResponseType & { data: FactoryTour },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.factoryTour,
                method: "POST",
                body
            })
        }),

        getAllTour: builder.query<
            CommonResponseType & { data: AllFactoryType },
            GetTokenParams
        >({
            query: ({ query, page }) => ({
                url: `${END_POINTS.factoryTour}?page=${page}&search=${query}`,
                method: "GET"
            })
        }),

        getFactoryTourById: builder.query<
            CommonResponseType & { data: FactoryTour },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.factoryTour}/${id}`,
                method: "GET",
            })
        }),

        updateFactoryTourById: builder.mutation<
            CommonResponseType & { data: FactoryTour },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.factoryTour}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteFactoryTour: builder.mutation<
            CommonResponseType & { data: FactoryTour },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.factoryTour}/${id}`,
                method: "DELETE"
            })
        }),
    })
})

export const {
    usePostFactoryTourMutation,
    useLazyGetAllTourQuery,
    useLazyGetFactoryTourByIdQuery,
    useUpdateFactoryTourByIdMutation,
    useDeleteFactoryTourMutation
} = FactoryApi;