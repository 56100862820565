import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { BannerType, CommonBody, Discount } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type AllCouponType = {
    count: number,
    page: number,
    data: Discount[]
}

type GetTokenParams = {
    page?: number;
    query?: string;
};

export const couponApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postCoupon: builder.mutation<
            CommonResponseType & { data: Discount },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.coupon,
                method: "POST",
                body
            })
        }),

        getAllCoupons: builder.query<
            CommonResponseType & { data: AllCouponType },
            GetTokenParams
        >({
            query: ({ query, page }) => ({
                url: `${END_POINTS.coupon}?page=${page}&search=${query}`,
                method: "GET"
            })
        }),

        getCouponById: builder.query<
            CommonResponseType & { data: Discount },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.coupon}/${id}`,
                method: "GET",
            })
        }),

        updateCouponById: builder.mutation<
            CommonResponseType & { data: Discount },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.coupon}/${id}`,
                method: "PUT",
                body
            })
        }),

        deleteCoupon: builder.mutation<
            CommonResponseType & { data: Discount },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.coupon}/${id}`,
                method: "DELETE"
            })
        }),


    })
})

export const {
    usePostCouponMutation,
    useLazyGetAllCouponsQuery,
    useLazyGetCouponByIdQuery,
    useUpdateCouponByIdMutation,
    useDeleteCouponMutation

} = couponApi;