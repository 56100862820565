import { Box, Button, Modal, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { generateResponsiveStyle } from "../utils/ModalStyle";

type props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    name: any[];
};

const UserNameBox = ({ open, setOpen, name }: props) => {
    const style = generateResponsiveStyle();
    console.log(name, "name array");

    return (
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            className="logout_mdl"
        >
            <Box sx={style}>
                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center", fontSize: "20px", color: "#1c6975" }}
                >
                    Customer's Name
                </Typography>
                <div className="scrollable-list">
                    {name?.length ? (
                        name?.map((item,i) => {
                            return (
                                <ul>
                                    <li>{i+1}{". "}{item?.fullName ? (item?.fullName) : (item?.firstName + " " + item?.lastName)}</li>
                                </ul>
                            )
                        })
                    ) : ("")}
                </div>


                <div className="flexdiv">
                    <Button
                        sx={{ color: "#252525", border: "1px solid #252525" }}
                        onClick={() => setOpen(false)}
                    >
                        Close
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default UserNameBox;
