import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { Dashboard } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type links = {
    exportUrlCsv: string;
    exportUrlXl: string;
    message: string;
}

export const dashboardApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getDashboard: builder.query<
            CommonResponseType & { data: Dashboard },
            {}
        >({
            query: () => ({
                url: `${END_POINTS.adminDashboard}`,
                method: "GET"
            })
        }),

        getUserGraph: builder.query<
            CommonResponseType & { data: any },
            { type: number, categoryId: string, geofenceId: string }
        >({
            query: ({ type, categoryId, geofenceId }) => ({
                url: `${END_POINTS.userGraph}?type=${type}&categoryId=${categoryId}&geofenceId=${geofenceId}`,
                method: "GET",
            }),
        }),

        getRevenueGraph: builder.query<
            CommonResponseType & { data: any },
            { type: number, categoryId: string, geofenceId: string }
        >({
            query: ({ type, categoryId, geofenceId }) => ({
                url: `${END_POINTS.totalRevenuGraph}?type=${type}&categoryId=${categoryId}&geofenceId=${geofenceId}`,
                method: "GET",
            }),
        }),

        getProductGraph: builder.query<
            CommonResponseType & { data: any },
            { type: number, categoryId: string, geofenceId: string }
        >({
            query: ({ type, categoryId, geofenceId }) => ({
                url: `${END_POINTS.totalProductsGraph}?type=${type}&categoryId=${categoryId}&geofenceId=${geofenceId}`,
                method: "GET",
            }),
        }),

        getOrderGraph: builder.query<
            CommonResponseType & { data: any },
            { type: number, categoryId: string, geofenceId: string }
        >({
            query: ({ type, categoryId, geofenceId }) => ({
                url: `${END_POINTS.totalOrderGraph}?type=${type}&categoryId=${categoryId}&geofenceId=${geofenceId}`,
                method: "GET",
            }),
        }),

        getProductExport: builder.query<CommonResponseType & { data: links }, {
        }>({
            query: () => ({
                url: `${END_POINTS.productsExports}`,
                method: "GET",
            }),
        }),
        getEarningExports: builder.query<CommonResponseType & { data: links }, {
        }>({
            query: () => ({
                url: `${END_POINTS.paymentsExports}`,
                method: "GET",
            }),
        }),
        getOrderExports: builder.query<CommonResponseType & { data: links }, {
        }>({
            query: () => ({
                url: `${END_POINTS.ordersExports}`,
                method: "GET",
            }),
        }),



    })
})

export const {
    useLazyGetDashboardQuery,
    useLazyGetUserGraphQuery,
    useLazyGetRevenueGraphQuery,
    useLazyGetProductGraphQuery,
    useLazyGetOrderGraphQuery,
    useLazyGetProductExportQuery,
    useLazyGetEarningExportsQuery,
    useLazyGetOrderExportsQuery,
} = dashboardApi;