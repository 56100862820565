import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Grid,
    Typography,
} from "@mui/material";

import MainContainer from "../../layout/MainContainer";
import { PaymentType } from "../../types/General";
import { useLazyGetPaymentByIdQuery } from "../../services/payments";
import { showError } from "../../constants/toast";

const PaymentDetails = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const [getPaymentDetails] = useLazyGetPaymentByIdQuery();
    const [payment, setPayment] = useState<PaymentType>();

    function convertToInternationalCurrencySystem(labelValue: number) {
        // Nine Zeroes for Billions
        return Math.abs(Number(labelValue)) >= 1.0e9
            ? (Math.abs(Number(labelValue)) / 1.0e9).toFixed(2) + " B"
            : // Six Zeroes for Millions
            Math.abs(Number(labelValue)) >= 1.0e6
                ? (Math.abs(Number(labelValue)) / 1.0e6).toFixed(2) + " M"
                : // Three Zeroes for Thousands
                Math.abs(Number(labelValue)) >= 1.0e3
                    ? (Math.abs(Number(labelValue)) / 1.0e3).toFixed(2) + " K"
                    : Math.abs(Number(labelValue));
    }

    const fetchDetails = async () => {
        try {
            const res = await getPaymentDetails({
                id: id
            }).unwrap()
            if (res?.statusCode === 200) {
                setPayment(res?.data || [])
            }
        } catch (error: any) {
            showError(error?.data?.message)
        }
    }
    useEffect(() => {
        if (id) {
            fetchDetails();
        }
    }, []);

    return (
        <MainContainer>
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng">View Payment Details</h1>
                    <Button
                        className="btn btn_primary"
                        onClick={() => {
                            navigate("/manage-revenue");
                        }}
                    >
                        Back
                    </Button>
                </div>
                <Card className="cards">
                    <CardContent sx={{ p: 1 }}>
                        <Grid container spacing={2} className="view_box">
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Typography
                                    className="detail_title mn_hdng"
                                    component="h2"
                                    mb={3}
                                >
                                    Payment Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12} className="view_box_list">
                                <Grid container spacing={3}>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Order Id</Typography>
                                            <Typography component="p">{payment?.orderId}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Transaction Id</Typography>
                                            <Typography component="p">{payment?.transactionId || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Payment Received</Typography>
                                            <Typography component="p">AED{" "}{convertToInternationalCurrencySystem(payment?.advanceAmountPaid || 0)}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Payment Mode</Typography>
                                            <Typography component="p"> {(() => {
                                                switch (payment?.paymentMode) {
                                                    case 1:
                                                        return "Card";
                                                    case 2:
                                                        return "Wallet";
                                                    case 3:
                                                        return "Cardwallet";
                                                    case 4:
                                                        return "Walletcash";
                                                    default:
                                                        return "-";
                                                }
                                            })()}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Customer Name</Typography>
                                            <Typography component="p">{payment?.user?.fullName}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5">Email
                                            </Typography>
                                            <Typography component="p">{payment?.user?.email}</Typography>
                                        </Box>
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={6} xs={12}>
                                        <Box>
                                            <Typography component="h5"> Phone Number</Typography>
                                            <Typography component="p">{payment?.user?.countryCode
                                                ? (payment?.user?.countryCode.includes("+") ? "" : "+") +
                                                payment?.user?.countryCode
                                                : null}{" "}
                                                {payment?.user?.phone || "-"}</Typography>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </div>
        </MainContainer>
    );
};

export default PaymentDetails;
