import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { useDeleteNotificationMutation, useLazyGetNotificationQuery } from "../../services/notification";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { showError } from "../../constants/toast";
import { Notification } from "../../types/General";
import moment from "moment";
import { Pagination, UserNameBox, WarnModal } from "../../components";
import { handleDelete } from "../../utils/commonFunctions";
import Loader from "../../constants/Loader";

const ManageNotifications = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [getAllNotification, { isLoading }] = useLazyGetNotificationQuery();
  const [deleteNotification] = useDeleteNotificationMutation();
  const [notData, setNotData] = useState<Notification[]>([]);
  const [selectedId, setSelectedId] = useState<string>("")
  const [page, setPage] = useState<number>(1);
  const [openBox, setOpenBox] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  let totalPages = Math.ceil(totalCount / 10);
  const UserData = useAuth();
  const [users, setUsers] = useState<any[]>([])

  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);

  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Manage Notifications"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const fetchNotification = async () => {
    try {
      const res = await getAllNotification({
        page: page,
        size: 10,
        query: debouncedSearchTerm.trim()
      }).unwrap();
      if (res?.statusCode === 200) {
        setNotData(res?.data?.notification)
        setTotalCount(res?.data?.notificationCount || 0)
      }
    } catch (error: any) {
      showError(error?.data?.message)
    }
  }


  useEffect(() => {
    checkPermission();
  }, [UserData])

  useEffect(() => {
    fetchNotification();
  }, [page, debouncedSearchTerm, searchTerm]);


  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Manage Notifications</h1>
        </div>
        <Card className="cards">
          <Box className="cards_header">
            <SearchBar
              searchTerm={searchTerm}
              setDebouncedSearchTerm={setDebouncedSearchTerm}
              value={searchTerm}
              onCross={() => setSearchTerm("")}
              onChange={(val: any) => {
                if (isValidInput(val.target.value)) {
                  setSearchTerm(val.target.value);
                }
              }}
            />
            <Box className="cards_header_right">
              {hidePermission?.isEdit || UserData?.role === 1 ? (
                <Button
                  className="btn btn_primary"
                  onClick={() => navigate("/manage-notifications/add")}
                >
                  Add Notification
                </Button>
              ) : null}
            </Box>
          </Box>
          <TableContainer className="table_container">
            <Box className="heading"></Box>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center">S.No</TableCell>
                  <TableCell align="center">Image</TableCell>
                  <TableCell align="center">Notification Title</TableCell>
                  <TableCell align="center">Notification Description</TableCell>
                  <TableCell align="center">Sent to</TableCell>
                  <TableCell align="center">Date</TableCell>
                  <TableCell align="center">Delete</TableCell>
                </TableRow>
              </TableHead>
              {notData?.length ? (
                notData.map((row, i) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                      <TableCell align="center" >
                        <figure className="user_img">
                          <img
                            src={row?.image ? (row?.image) : ("/static/images/user_placeholder.png")}
                            alt=""
                          />
                        </figure>
                      </TableCell>
                      <TableCell align="center">{row?.title}</TableCell>
                      <TableCell align="center">{row?.message}</TableCell>
                      <TableCell align="center">
                        {row?.type === 1 ? ("All") : (
                          <Button
                            style={{ textTransform: "capitalize", backgroundColor: "#029be0" }}
                            onClick={() => { setOpenBox(true); setUsers(row?.userNames) }}
                          >
                            View users
                          </Button>
                        )}

                      </TableCell>
                      <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY / HH:MM:SS")}</TableCell>
                      <TableCell align="center">
                        <Box className="table_actions">
                          {hidePermission?.isEdit || UserData?.role === 1 ? (
                            <Tooltip title="Delete">
                              <IconButton
                                onClick={() => {
                                  setSelectedId(row?._id)
                                  setOpen(true)
                                }}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          ) : null}
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )
                )
              ) : (
                <TableBody>
                  <TableCell
                    align="center"
                    colSpan={10}
                    sx={{ color: "#051140" }}
                  >
                    No Data Found
                  </TableCell>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Card>
      </div>
      <Pagination
        module={notData}
        page={page}
        onPageChange={onPageChange}
        totalPages={totalPages}
      />
      <WarnModal
        name="notification"
        open={open}
        setOpen={setOpen}
        handleDelete={() => handleDelete(deleteNotification, selectedId, fetchNotification)}
      />
      <UserNameBox open={openBox} setOpen={setOpenBox} name={users} />
    </MainContainer>
  );
};

export default ManageNotifications;
