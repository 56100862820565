import {
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import MainContainer from "../../layout/MainContainer";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useFormik, validateYupSchema } from "formik";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { CommonBody, varients } from "../../types/General";
import { useLazyGetVarientByIdQuery, usePostVarientMutation, useUpdateVarientMutation } from "../../services/varint";
import * as Yup from "yup";


const AddSize = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [postVarient] = usePostVarientMutation();
  const [selectedValue, setSelectedValue] = useState("");
  const [updateSize] = useUpdateVarientMutation();
  const [getSizeById] = useLazyGetVarientByIdQuery();
  const [variant, setVariant] = useState<varients>();
  const location = useLocation()
  const { state } = location

  console.log(state.data)
  const getCategoryDetail = async (id: string | undefined) => {
    try {
      const res = await getSizeById({ id }).unwrap();
      if (res?.statusCode === 200) {
        setVariant(res?.data[0]);
        setSelectedValue(res?.data[0]?.label)
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.data?.message || "");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      value: variant?.value || "",
      // label: selectedValue || "",
    },
    validationSchema: Yup.object({
      value: Yup.string()
        .required("Size is required")
    }),
    onSubmit: async (values) => {
      formik.setSubmitting(true);
      let body = {
        parentId: "65f975fd46c9cb3d34866353",
        value: values.value,
        label: selectedValue,
      };
      if (!selectedValue) {
        showError("label is required")
        return;
      }
      if (id) {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await updateSize({ body: encryptedBody, id }).unwrap();
          if (response.statusCode === 200) {
            showToast("Size updated succesfuly");
            navigate("/manage-sizes");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      } else {
        try {
          let encryptedBody = generateEncryptedKeyBody(body) as CommonBody;
          const response = await postVarient(encryptedBody).unwrap();
          if (response.statusCode === 200) {
            showToast("Size added succesfuly");
            navigate("/manage-sizes");
          }
        } catch (error: any) {
          showError(error?.data?.message || "");
        }
      }
    },
  });

  const handleSelectChange = (event: any) => {
    setSelectedValue(event.target.value as string);
  };

  useEffect(() => {
    if (id) {
      getCategoryDetail(id);
    }
  }, [])
  return (
    <MainContainer>
      <div className="main_loyout">
        <div className="dashboard">
          <h1>{state.data === "edit" ? "Edit Size" : "Add Size"}</h1>
          <Button
            className="btn btn_primary"
            onClick={() => {
              navigate("/manage-sizes");
            }}
          >
            Back
          </Button>
        </div>
        <Card className="cards">
          <form onSubmit={formik.handleSubmit}>
            <CardContent sx={{ p: 1 }}>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Typography className="custom_label">{state.data === "edit" ? "Edit Size" : "Add Size"}</Typography>
                  <TextField
                    sx={{ margin: "0px"}}
                    className="text_field"
                    hiddenLabel
                    type="text"
                    name="value"
                    variant="outlined"
                    fullWidth
                    placeholder="Size"
                    value={formik.values.value}
                    onChange={formik.handleChange}
                    helperText={formik.touched.value && formik.errors.value}
                  />
                </Grid>
                <Grid item lg={4} md={4} sm={4} xs={12}>
                  <Typography className="custom_label">
                    Select Unit
                  </Typography>

                  <FormControl sx={{ width: "100%" }}>
                    <Select
                      className="select_div"
                      fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedValue}
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      displayEmpty
                      onChange={handleSelectChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="inch">inch</MenuItem>
                      <MenuItem value="mm">mm</MenuItem>
                      <MenuItem value="cm">cm</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div className="form_btn">
                <Button
                  size="large"
                  type="submit"
                  className="btn btn_primary"
                >
                  Save
                </Button>
              </div>
            </CardContent>
          </form>
        </Card>
      </div>
    </MainContainer>
  );
};

export default AddSize;







