import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody } from "../types/General";
import { User } from "../types/User"

type CommonResponseType = {
    statusCode: number;
    message: string;
};


export const authApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postFaq: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.faq,
                method: "POST",
                body,
            }),
        }),

        putFaq: builder.mutation<
            CommonResponseType & { data: User },
            CommonBody
        >({
            query: (body) => ({
                url: END_POINTS.faq,
                method: "PUT",
                body,
            }),
        }),



        getFaq: builder.query<CommonResponseType & { data: User }, {}>({
            query: () => ({
                url: END_POINTS.faq,
                method: "GET",
            }),
        }),

        deleteFaq: builder.query<CommonResponseType, { _id: string }>({
            query: ({ _id }) => ({
                url: `${END_POINTS.faq}/${_id}`,
                method: "DELETE",
            }),
        }),

    })
})


export const {
    usePostFaqMutation,
    useLazyGetFaqQuery,
    usePutFaqMutation,
    useLazyDeleteFaqQuery
} = authApi;






