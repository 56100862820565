import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import {
  Button,
  Card,
  FormControl,
  IconButton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";

import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import { useDeleteCouponMutation, useLazyGetAllCouponsQuery, useUpdateCouponByIdMutation } from "../../services/coupon";
import { CommonBody, Discount, TruckCategory } from "../../types/General";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import Loader from "../../constants/Loader";
import { useLazyGetCategoryByIdQuery } from "../../services/categories";
import { Pagination, WarnModal } from "../../components";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

const ManageDiscount = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [open, setOpen] = React.useState(false);
  const [getAllCoupon, { isLoading }] = useLazyGetAllCouponsQuery();
  const [updateStatus] = useUpdateCouponByIdMutation();
  const [deleteCoupon] = useDeleteCouponMutation();
  const [couponData, setCouponData] = useState<Discount[]>([]);
  const [selectedId, setSelectedId] = useState<string>("");
  const UserData = useAuth();
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  let totalPages = Math.ceil(totalCount / 10);
  const userPermissions = UserData?.permissions?.length
  ? (UserData?.permissions)
  : [];


  //get all  api
  const getCoupon = async () => {
    try {
      const response = await getAllCoupon({
        query: debouncedSearchTerm.trim(),
        page: page,
      },).unwrap();
      if (response?.statusCode === 200) {
        setTotalCount(response?.data?.count);
        setCouponData(response?.data?.data || [])
      } else {
        setCouponData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };
  console.log(couponData, "couponData.....................");

  //update coupon status api
  const updateCouponStatus = async (id: string, status: boolean) => {
    const data = {
      isBlocked: status,
    };
    try {
      let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
      const res = await updateStatus({ id, body: encryptedBody }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Coupon status updated successfully");
        await getCoupon();
      }
    } catch (error: any) {
      console.log(error, "error");
      showError(error?.message || "Error updating Coupon status");
    }
  };

  //delete  api
  const handleDeleteCoupon = async (id: string) => {
    try {
      const res = await deleteCoupon({ id }).unwrap();
      if (res?.statusCode === 200) {
        showToast("Coupon deleted successfully");
        await getCoupon();
      }
    } catch (error: any) {
      console.log(error, "errror");
      showError(error?.message || "");
    }
  };

  useEffect(() => {
    getCoupon();
  }, [page, debouncedSearchTerm])

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Discount and Coupon Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };


  useEffect(() => {
    checkPermission();
  }, [UserData])



  const label = { inputProps: { "aria-label": "Switch demo" } };
  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Discount and Coupon Management</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="cards_header">
              <SearchBar
                searchTerm={searchTerm}
                setDebouncedSearchTerm={setDebouncedSearchTerm}
                value={searchTerm}
                onCross={() => setSearchTerm("")}
                onChange={(val: any) => {
                  if (isValidInput(val.target.value)) {
                    setSearchTerm(val.target.value);
                  }
                }}
              />
              <Box className="cards_header_right">
                {hidePermission?.isEdit || UserData?.role === 1 ? (
                  <Button
                    className="btn btn_primary"
                    onClick={() => navigate("/discount-and-coupon/add")}
                  >
                    Add Discount Coupon
                  </Button>
                ) : null}
              </Box>
            </Box>
            <TableContainer className="table_container">
              <Box className="heading"></Box>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">S.No</TableCell>
                    <TableCell align="center"> Offer/Discount Name</TableCell>
                    <TableCell align="center"> Category</TableCell>
                    <TableCell align="center">Coupon code</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                  </TableRow>
                </TableHead>
                {couponData?.length ? (
                  couponData.map((row, i) => {
                    return (
                      <TableBody key={row?._id}>
                        <TableRow>
                          <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                          <TableCell align="center">
                            {row?.name}
                          </TableCell>
                          <TableCell align="center">
                            {row?.categoryName}
                          </TableCell>
                          <TableCell align="center">
                            {row?.code}
                          </TableCell>
                          <TableCell align="center">
                            <Switch
                              {...label}
                              size="small"
                              checked={!row?.isBlocked}
                              onChange={() =>
                                updateCouponStatus(row?._id, !row?.isBlocked)
                              }
                            />
                          </TableCell>
                          <TableCell align="center">
                            <Box className="table_actions">
                              {hidePermission?.isEdit || UserData?.role === 1 ? (
                                <Tooltip title="Edit">
                                  <IconButton
                                    onClick={() => navigate(`/discount-and-coupon/edit/${row?._id}`)}
                                  >
                                    <ModeEditIcon />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                              {hidePermission?.isDelete || UserData?.role === 1 ? (
                                <Tooltip title="Delete">
                                  <IconButton>
                                    <DeleteIcon
                                      onClick={() => {
                                        setOpen(true);
                                        setSelectedId(row?._id);
                                      }}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    );
                  })
                ) : (
                  <TableBody>
                    <TableCell
                      align="center"
                      colSpan={10}
                      sx={{ color: "#051140" }}
                    >
                      No Data Found
                    </TableCell>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Card>
        <Pagination
          module={couponData}
          page={page}
          onPageChange={onPageChange}
          totalPages={totalPages}
        />
      </div>
      <WarnModal
        name="coupon"
        setOpen={setOpen}
        open={open}
        handleDelete={() => handleDeleteCoupon(selectedId)}
      />
    </MainContainer>
  );
};

export default ManageDiscount;
