import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { User } from "../types/User";
import { CommonBody, UserData } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    query?: string;
    liveUsers?: boolean;
    newUsers?: boolean;
    fromDate?: string;
    toDate?: string;

};
type GetUserData = {
    count: number;
    pages: number;
    user: UserData[]
}

export const userApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        editUser: builder.mutation<
            CommonResponseType & { data: UserData },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "PUT",
                body
            })
        }),

        getAllUsers: builder.query<
            CommonResponseType & { data: GetUserData },
            GetTokenParams
        >({
            query: ({ page, query, liveUsers, newUsers, fromDate, toDate }) => ({
                url: `${END_POINTS.user}?search=${query}&page=${page}&size=${10}&liveUsers=${liveUsers}&newUsers=${newUsers}&fromDate=${fromDate}&toDate=${toDate}`,
                method: "GET",
            }),
        }),

        getAllUserswithoutPage: builder.query<
            CommonResponseType & { data: GetUserData },
            {}
        >({
            query: () => ({
                url: `${END_POINTS.user}`,
                method: "GET",
            }),
        }),

        getUserCSV: builder.query<CommonResponseType & { data: string }, {

        }>({
            query: () => ({
                url: `${END_POINTS.userExports}`,
                method: "GET",
            }),
        }),

        getUserExcel: builder.query<CommonResponseType & { data: string }, {

        }>({
            query: () => ({
                url: `${END_POINTS.userExportsXL}`,
                method: "GET",
            }),
        }),

        getUserById: builder.query<
            CommonResponseType & { data: UserData },
            { id: string }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "GET",
            }),
        }),

        deleteUser: builder.mutation<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.user}/${id}`,
                method: "DELETE",
            }),
        }),

        getUserReports: builder.query<
            CommonResponseType & { data: any },
            { id: string | undefined, query: string | undefined }
        >({
            query: ({ id, query }) => ({
                url: `${END_POINTS.userReports}/${id}?search=${query}`,
                method: "GET",
            }),
        }),

        getProductsById: builder.query<
            CommonResponseType & { data: any },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.getProductsByUserId}/${id}`,
                method: "GET",
            })
        }),
    })
})

export const {

    // usePostUserMutation,
    useEditUserMutation,
    useLazyGetAllUsersQuery,
    useLazyGetAllUserswithoutPageQuery,
    useLazyGetUserCSVQuery,
    useLazyGetUserExcelQuery,
    useLazyGetUserByIdQuery,
    useDeleteUserMutation,
    useLazyGetUserReportsQuery,
    useLazyGetProductsByIdQuery
} = userApi;