import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, varients } from "../types/General";
import { User } from "../types/User"

type CommonResponseType = {
  statusCode: number;
  message: string;
};
type GetTokenParams = {
  page?: number;
  query?: string;
  size?: number;
  type?: string;
}
type VarientsArray = {
  count: number;
  pages: number;
  variant: varients[];
  message: string;
}

export const varientApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    postVarient: builder.mutation<
      CommonResponseType & { data: varients },
      CommonBody
    >({
      query: (body) => ({
        url: `${END_POINTS.variant}`,
        method: "POST",
        body,
      }),
    }),

    getAllVarients: builder.query<
      CommonResponseType & { data: VarientsArray },
      GetTokenParams
    >({
      query: ({ page, size, query }) => ({
        url: `${END_POINTS.variant}?page=${page}&size=${size}&search=${query}`,
        method: "GET",
      }),
    }),
    getVarients: builder.query<
      CommonResponseType & { data: VarientsArray },
      {}
    >({
      query: ({ }) => ({
        url: `${END_POINTS.variant}`,
        method: "GET",
      }),
    }),

    getVarientById: builder.query<
      CommonResponseType & { data: varients[] },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.variant}/${id}`,
        method: "GET",
      }),
    }),

    updateVarient: builder.mutation<
      CommonResponseType & { data: varients },
      { id: string | undefined, body: CommonBody }
    >({
      query: ({ body, id }) => ({
        url: `${END_POINTS.variant}/${id}`,
        method: "PUT",
        body
      }),
    }),

    deleteVarient: builder.mutation<
      CommonResponseType & { data: varients },
      { id: string | undefined }
    >({
      query: ({ id }) => ({
        url: `${END_POINTS.variant}/${id}`,
        method: "DELETE",
      }),
    }),

    // getProductsById: builder.query<
    //   CommonResponseType & { data: varients },
    //   { id: string | undefined }
    // >({
    //   query: ({ id }) => ({
    //     url: `${END_POINTS.getProductsByCategoryId}/${id}`,
    //     method: "GET",
    //   }),
    // }),

  })
});

export const {
  usePostVarientMutation,
  useLazyGetAllVarientsQuery,
  useLazyGetVarientByIdQuery,
  useUpdateVarientMutation,
  useDeleteVarientMutation,
  useLazyGetVarientsQuery
} = varientApi;