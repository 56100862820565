import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, InventoryVariant } from "../types/General";
import { User } from "../types/User"

type CommonResponseType = {
    statusCode: number;
    message: string;
};
type ResponseData = {
    count: number;
    pages: number;
    productVariant: InventoryVariant[];
}

export const inventoryApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        getInventory: builder.query<
            CommonResponseType & { data: ResponseData },
            { id: string | undefined, query: string, page: number, size: number, fromDate?: string, toDate?: string }
        >({
            query: ({ id, query, page, size, fromDate, toDate }) => ({
                url: `${END_POINTS.productVariant}/${id}?search=${query}&page=${page}&size=${size}&fromDate=${fromDate}&toDate=${toDate}`,
                method: "GET"
            })
        }),

        updateInventory: builder.mutation<
            CommonResponseType & { data: InventoryVariant },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.productVariant}/${id}`,
                method: "PUT",
                body,
            }),
        }),


    })
})

export const {
    useLazyGetInventoryQuery,
    useUpdateInventoryMutation,

} = inventoryApi;