import LoginPage from "./auth/login";
import DashBoardPage from "./dashBoard";
import ManageUsers from "./users";
import ForgotPasswordPage from "./auth/forgotPassword";
import VerifyOtp from "./auth/otp";
import AddBanner from "./adBanner/add";
import Analytics from "./analytics";
import Faq from "./faq";
import AddDiscount from "./manageDiscount/add";
import ManageDiscount from "./manageDiscount";
import ResetPassword from "./auth/resetPassword";
import Profile from "./profile";
import ManageCms from "./manageCms";
import ManageNotifications from "./manageNotification";
import AddNotification from "./manageNotification/add";
import CustomerSupport from "./customerSupport";
import ManageSubAdmin from "./manageSubAdmin";
import AddSubAdmin from "./manageSubAdmin/add";
import ManageCategories from "./manageCategories";
import AddCategories from "./manageCategories/add";
import RecievedNotifications from "./manageNotification/receivedNotification";
import SubCategories from "./manageCategories/details";
import ProductOrderDetails from "./productOrderManagement/details";
import ChangePassword from "./auth/changePassword";
import ManageAmenities from "./manageReview";
import AddAmenities from "./manageReview/add";
import ManageServiceProvider from "./productOrderManagement";
import ManageRevenue from "./revenueManagement";
import ManageWords from "./adBanner";
import ManageInventory from "./inventoryManagement";
import ManageProducts from "./manageProducts";
import AdBanner from "./adBanner";
import AddSize from "./manageSizes/add";
import ContentDetails from "./manageProducts/details";
import ManageRewards from "./manageDiscount";
import ManageVariants from "./inventoryManagement/variants";
import AddRewards from "./manageDiscount/add";
import ManageAdvertisements from "./manageSizes";
import AddProductForm from "./manageProducts/form";
import ProductDetails from "./inventoryManagement/details";
import ManageReviews from "./manageReview";
import ReviewDetails from "./manageReview/add";
import ManageSizes from "./manageSizes";
import ManageGeofence from "./geofence";
import AddGeofence from "./geofence/add";
import PaymentDetails from "../features/managePayments/details";
import ManageBulkOrders from "./manageBulkOrders";
import BulkOrderDetails from "./manageBulkOrders/details";
import FactoryTourIndex from "./factoryTour";
import AddFactoryTour from "./factoryTour/add";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  LoginPage,
  AdBanner,
  ManageBulkOrders,
  FactoryTourIndex,
  AddFactoryTour,
  BulkOrderDetails,
  PaymentDetails,
  ManageVariants,
  ManageSizes,
  ReviewDetails,
  AddSize,
  ManageReviews,
  DashBoardPage,
  AddBanner,
  ManageUsers,
  ForgotPasswordPage,
  ManageServiceProvider,
  VerifyOtp,
  Analytics,
  AddDiscount,
  ManageDiscount,
  Faq,
  ResetPassword,
  ProductOrderDetails,
  Profile,
  ManageCms,
  ManageNotifications,
  AddNotification,
  CustomerSupport,
  ManageSubAdmin,
  AddSubAdmin,
  ManageCategories,
  AddCategories,
  RecievedNotifications,
  SubCategories,
  ChangePassword,
  ManageAmenities,
  AddAmenities,
  ManageRevenue,
  ManageWords,
  ManageInventory,
  ManageProducts,
  ContentDetails,
  ManageRewards,
  AddRewards,
  ManageAdvertisements,
  AddProductForm,
  ProductDetails,
  ManageGeofence,
  AddGeofence,
};
