import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, ProductData, ratingType } from "../types/General";
import { string } from "yup";


type CommonResponseType = {
    statusCode: number;
    message: string;
};

type Product = {
    count: number;
    product: ProductData[]
    page: number
}

type GetTokenParams = {
    page?: number;
    query?: string;
    size?: number;
    type?: string;
    fromDate?:string;
    toDate?:string;
}

type ratingResponce = {
    count: number;
    page: number;
    data: ratingType[];
}

export const ProductApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({


        addProduct: builder.mutation<
            CommonResponseType & { data: ProductData },
            { body: CommonBody }
        >({
            query: ({ body }) => ({
                url: `${END_POINTS.product}`,
                method: "POST",
                body
            })
        }),

        getProducts: builder.query<
            CommonResponseType & { data: Product },
            GetTokenParams

        >({
            query: ({ page, query, size, fromDate, toDate }) => ({
                url: `${END_POINTS.product}/?page=${page}&size=${size}&search=${query}&fromDate=${fromDate}&toDate=${toDate}`,
                method: "GET"
            })
        }),

        getProductsWithoutPage: builder.query<
            CommonResponseType & { data: Product },
            {}

        >({
            query: () => ({
                url: `${END_POINTS.product}`,
                method: "GET"
            })
        }),


        productDetails: builder.query<
            CommonResponseType & { data: ProductData },
            { id?: string }

        >({
            query: ({ id }) => ({
                url: `${END_POINTS.product}/${id}`,
                method: "GET"
            })
        }),

        getProductsBySubCategoryId: builder.query<
            CommonResponseType & { data: Product },
            GetTokenParams & { id: string }
        >({
            query: ({ page, size, id }) => ({
                url: `${END_POINTS.getProductsBySubCategoryId}/${id}/?page=${page}&size=${size}`,
                method: "GET"
            })
        }),


        deleteProducts: builder.query<
            CommonResponseType & { data: ProductData },
            { id?: string }

        >({
            query: ({ id }) => ({
                url: `${END_POINTS.product}/${id}`,
                method: "Delete"
            })
        }),
        statusChange: builder.query<
            CommonResponseType & { data: ProductData },
            { id?: string, body?: any }

        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.product}/${id}`,
                method: "PUT",
                body
            })
        }),
        updateProducts: builder.mutation<
            CommonResponseType & { data: ProductData },
            { id: string, body: CommonBody }

        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.product}/${id}`,
                method: "PUT",
                body
            })
        }),

        //ratings and review
        getRating: builder.query<
            CommonResponseType & { data: ratingResponce },
            { id: string | undefined, page: number, query: string, size: number }>({
                query: ({ id, page, size, query }) => ({
                    url: `${END_POINTS.getRatingByProductId}/${id}/?page=${page}&size=${size}&search=${query}`,
                    method: "GET"
                })
            }),

        getRatingById: builder.query<
            CommonResponseType & { data: ratingType },
            { id: string | undefined, userId: string | null }
        >({
            query: ({ id, userId }) => ({
                url: `${END_POINTS.getRatingByProductId}/${id}?userId=${userId}`,
                method: "GET"
            })
        }),
        deleteRatings: builder.query<
            CommonResponseType & { data: ratingType },
            { id?: string }

        >({
            query: ({ id }) => ({
                url: `${END_POINTS.deleteRating}/${id}`,
                method: "Delete"
            })
        }),
    }),
});

export const {
    useAddProductMutation,
    useLazyGetProductsQuery,
    useLazyGetProductsWithoutPageQuery,
    useLazyProductDetailsQuery,
    useLazyGetProductsBySubCategoryIdQuery,
    useLazyDeleteProductsQuery,
    useLazyStatusChangeQuery,
    useUpdateProductsMutation,
    useLazyGetRatingQuery,
    useLazyGetRatingByIdQuery,
    useLazyDeleteRatingsQuery
} = ProductApi