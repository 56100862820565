import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import { Card, Box, Button, Tabs, Tab, Typography, FormControl, NativeSelect, Grid, TextField, InputAdornment, IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import PAST from "../../features/serviceProvider/past";
import PENDING from "../../features/serviceProvider/pending";
import ClearIcon from '@mui/icons-material/Clear';
import ONGOING from "../../features/serviceProvider/ongoing";
import CANCEL from "../../features/serviceProvider/cancel";
import ALL from "../../features/serviceProvider/all";
import { SearchBar } from "../../components";
import { isValidInput } from "../../utils/validations";
import { useLazyGetOrdersQuery } from "../../services/orders";
import { showError } from "../../constants/toast";
import { Console } from "console";
import { OrderData } from "../../types/General";
import Loader from "../../constants/Loader";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ManageServiceProvider = () => {
  const navigate = useNavigate();
  const { timeFilter } = useParams();
  const UserData = useAuth();
  const [value, setValue] = useState<number>(0);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const [getOrders, { isLoading }] = useLazyGetOrdersQuery();
  const [orderData, setOrderData] = useState<OrderData[]>([]);
  const [selectedTime, setSelectedTime] = useState<number>(1)
  const [totalCount, setTotalCount] = useState<number>(0);
  const [page, setPage] = useState(1);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  const [hidePermission, setHidePermission] = useState<
    Permissions | null | undefined
  >(null);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const handleDateChange = (event: any) => {
    setStartDate(event.target.value);
  };

  const handleDateChange1 = (event: any) => {
    setEndDate(event.target.value);
  };



  const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

  const checkPermission = () => {
    const permission = userPermissions;
    if (permission?.length) {
      let idx = -1;
      idx = permission?.findIndex(
        (ele: Permissions) => ele?.label === "Product Order Management"
      );
      if (idx > -1) {
        setHidePermission(permission[idx]);
      } else {
        navigate(-1);
      }
    }
  };
  const today = new Date().toISOString().split('T')[0];


  //get all varients 
  const fetchOrderData = async () => {
    try {
      const response = await getOrders({
        type: value,
        query: debouncedSearchTerm.trim(),
        timeFilter: selectedTime,
        page: page,
        fromDate: startDate,
        toDate: endDate,
      },).unwrap();
      console.log(value, "value++++++++++++++++");
      if (response?.statusCode === 200) {
        setOrderData(response?.data?.data || [])
        setTotalCount(response?.data?.count)
        console.log(setOrderData, "setOrderData")
      }
      else {
        setOrderData([]);
      }
    } catch (error: any) {
      showError(error?.data?.message || "");
    }
  };

  useEffect(() => {
    checkPermission();
  }, [UserData])


  useEffect(() => {

    fetchOrderData()
  }, [value, selectedTime, startDate, endDate])

  return (
    <MainContainer>
      <Loader isLoad={isLoading} />
      <div className="main_loyout">
        <div className="dashboard">
          <h1 className="mn_hdng">Product Orders</h1>
        </div>
        <Card className="cards">
          <Box className="custom_tabs">
            <Box className="flx_sc">
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="basic tabs example"
                className="custom_tabs_links"
              >
                <Tab label="All Orders" {...a11yProps(0)} />
                <Tab label="Pending Orders" {...a11yProps(1)} />
                <Tab label="Ongoing Orders" {...a11yProps(2)} />
                <Tab label="Past Orders" {...a11yProps(3)} />
                <Tab label="Rejected Orders" {...a11yProps(4)} />
              </Tabs>
            </Box>
            {/* <Grid container spacing={2} className="dashGrid"> */}
            <Box className="cards_header">
              <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                <SearchBar
                  searchTerm={searchTerm}
                  setDebouncedSearchTerm={setDebouncedSearchTerm}
                  value={searchTerm}
                  onCross={() => setSearchTerm("")}
                  onChange={(val: any) => {
                    if (isValidInput(val.target.value)) {
                      setSearchTerm(val.target.value);
                    }
                  }
                  }
                />
                <FormControl sx={{ width: "250px" }}>
                  <NativeSelect
                    defaultValue={1}
                    inputProps={{
                      name: "report",
                      id: "uncontrolled-native",
                    }}
                    onChange={(event) => setSelectedTime(Number(event.target.value))}
                  >
                    <option value={1}>All Time</option>
                    <option value={2}>Last 1 year</option>
                    <option value={3}>Last 6 months</option>
                    <option value={4}>Last 3 months</option>
                    <option value={5}>Last month</option>
                    <option value={6}>Last week</option>
                    <option value={7}>Today</option>
                  </NativeSelect>
                </FormControl>
              </Box>

              {/* <Box className="cards_header_right">
                <Button className="btn btn_primary">
                  <FileDownloadIcon /> Export CSV
                </Button>
              </Box> */}
            </Box>
            <Box className="crds_date" >
              <div>
                <Typography>Start Date</Typography>
                <TextField
                  // hiddenTypography
                  type="date"
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  placeholder="Start Time"
                  className="text_field"
                  inputProps={{ maxLength: 30,max:today }}
                  sx={{ maxWidth: "260px" }}
                  value={startDate}
                  onChange={handleDateChange}
                  InputProps={{
                    endAdornment: (
                      startDate && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleDateChange({ target: { value: '' } })}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    ),
                  }}
                />
              </div>
              <div>
                <Typography>End Date</Typography>
                <TextField
                  hiddenLabel
                  type="date"
                  name="startDate"
                  variant="outlined"
                  fullWidth
                  placeholder="Start Time"
                  className="text_field"
                  sx={{ maxWidth: "260px" }}
                  inputProps={{ maxLength: 30 ,max:today}}
                  value={endDate}
                  onChange={handleDateChange1}
                  InputProps={{
                    endAdornment: (
                      endDate && (
                        <InputAdornment position="end">
                          <IconButton onClick={() => handleDateChange1({ target: { value: '' } })}>
                            <ClearIcon />
                          </IconButton>
                        </InputAdornment>
                      )
                    ),
                  }}

                />
              </div>
            </Box>
            {/* </Grid> */}
            <CustomTabPanel value={value} index={0}>
              <ALL Data={orderData} count={totalCount} fetchOrderData={fetchOrderData} page={page} onPageChange={onPageChange} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <PENDING Data={orderData} count={totalCount} fetchOrderData={fetchOrderData} page={page} onPageChange={onPageChange} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <ONGOING Data={orderData} count={totalCount} fetchOrderData={fetchOrderData} page={page} onPageChange={onPageChange} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <PAST Data={orderData} count={totalCount} page={page} fetchOrderData={fetchOrderData} onPageChange={onPageChange} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={4}>
              <CANCEL Data={orderData} count={totalCount} fetchOrderData={fetchOrderData} page={page} onPageChange={onPageChange} />
            </CustomTabPanel>
          </Box>
        </Card>
      </div>
    </MainContainer>
  );
};

export default ManageServiceProvider;
