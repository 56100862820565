import React, { useEffect, useState } from "react";
import MainContainer from "../../layout/MainContainer";
import AddIcon from "@mui/icons-material/Add";
import {
    Button,
    Card,
    IconButton,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
} from "@mui/material";
import Box from "@mui/material/Box";
import SearchBar from "../../components/SearchBar";
import { useNavigate } from "react-router-dom";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isValidInput } from "../../utils/validations";
import {  useUpdateBannerByIdMutation } from "../../services/banners";
import { CommonBody, FactoryTour } from "../../types/General";
import Loader from "../../constants/Loader";
import { showError, showToast } from "../../constants/toast";
import { generateEncryptedKeyBody } from "../../utils/crypto";
import { Pagination, WarnModal } from "../../components";
import moment from "moment";
import useAuth from "../../hooks/useAuth";
import { Permissions } from "../../types/User";
import { useDeleteFactoryTourMutation, useLazyGetAllTourQuery, useUpdateFactoryTourByIdMutation } from "../../services/factoryTour";

const FactoryTourIndex = () => {
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [totalCount, setTotalCount] = useState<number>(0);
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [debouncedSearchTerm, setDebouncedSearchTerm] = useState<string>("");
    const [getAllFactoryTour, { isLoading }] = useLazyGetAllTourQuery();
    const [deleteFactoryTour] = useDeleteFactoryTourMutation();
    const [selectedId, setSelectedId] = useState<string>("");
    const [updateStatus] = useUpdateFactoryTourByIdMutation();
    const [factoryData, setFactoryData] = useState<FactoryTour[]>([]);
    const label = { inputProps: { "aria-label": "Switch demo" } };
    const [open, setOpen] = React.useState(false);
    const [hidePermission, setHidePermission] = useState<
        Permissions | null | undefined
    >(null);
    const UserData = useAuth();

    const userPermissions = UserData?.permissions?.length
    ? (UserData?.permissions)
    : [];

    const onPageChange = (newPage: number) => {
        setPage(newPage);
    };
    let totalPages = Math.ceil(totalCount / 10);

    //get all user api
    const getFactoryDataFun = async () => {
        try {
            const response = await getAllFactoryTour({
                query: debouncedSearchTerm.trim(),
                page: page,
            },).unwrap();
            if (response?.statusCode === 200) {
                setTotalCount(response?.data?.count);
                setFactoryData(response?.data?.data || [])
            } else {
                setFactoryData([]);
            }
        } catch (error: any) {
            showError(error?.data?.message || "");
        }
    };

    //update banner status api
    const updateBannerStatus = async (id: string, status: boolean) => {
        const data = {
            isBlocked: status,
        };
        try {
            let encryptedBody = generateEncryptedKeyBody(data) as CommonBody;
            const res = await updateStatus({ id, body: encryptedBody }).unwrap();
            if (res?.statusCode === 200) {
                showToast("Banner status updated successfully");
                await getFactoryDataFun();
            }
        } catch (error: any) {
            console.log(error, "error");
            showError(error?.message || "Error updating banner status");
        }
    };


    //delete banner api
    const handleDeleteFactoryTour = async (id: string) => {
        try {
            const res = await deleteFactoryTour({ id }).unwrap();
            if (res?.statusCode === 200) {
                showToast("Factory tour deleted successfully");
                await getFactoryDataFun();
            }
        } catch (error: any) {
            console.log(error, "errror");
            showError(error?.message || "");
        }
    };

    useEffect(() => {
        getFactoryDataFun();
    }, [page, debouncedSearchTerm]);

    const checkPermission = () => {
        const permission = userPermissions;
        if (permission?.length) {
            let idx = -1;
            idx = permission?.findIndex(
                (ele: Permissions) => ele?.label === "Manage Factory Tour"
            );
            if (idx > -1) {
                setHidePermission(permission[idx]);
            } else {
                navigate(-1);
            }
        }
    };


    useEffect(() => {
        checkPermission();
    }, [UserData])


    return (
        <MainContainer>
            <Loader isLoad={isLoading} />
            <div className="main_loyout">
                <div className="dashboard">
                    <h1 className="mn_hdng"> Manage Factory Tour</h1>
                </div>
                <Card className="cards">
                    <Box className="cards_header">
                        <SearchBar
                            searchTerm={searchTerm}
                            setDebouncedSearchTerm={setDebouncedSearchTerm}
                            value={searchTerm}
                            onCross={() => setSearchTerm("")}
                            onChange={(val: any) => {
                                if (isValidInput(val.target.value)) {
                                    setSearchTerm(val.target.value);
                                }
                            }
                            }
                        />
                        <Box className="cards_header_right">
                            {hidePermission?.isEdit || UserData?.role === 1 ? (
                                <Button
                                    className="btn btn_primary"
                                    onClick={() => navigate("/manage-factory-tour/add")}
                                >
                                    Add Factory Tour
                                </Button>
                            ) : null}
                        </Box>
                    </Box>
                    <TableContainer className="table_container">
                        <Box className="heading"></Box>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center">S.No</TableCell>
                                    <TableCell align="center">Image</TableCell>
                                    <TableCell align="center">Title</TableCell>
                                    <TableCell align="center">Status</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {factoryData?.length ? (
                                factoryData.map((row, i) => {
                                    return (
                                        <TableBody key={row?._id}>
                                            <TableRow>
                                                <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                                                <TableCell align="center">
                                                    <figure className="user_img">
                                                        <img
                                                            src={row?.image}
                                                            alt=""
                                                        />
                                                    </figure>
                                                </TableCell>
                                                <TableCell align="center">{row?.title}</TableCell>
                                                <TableCell align="center">
                                                    <Switch
                                                        {...label}
                                                        size="small"
                                                        checked={!row?.isBlocked}
                                                        onChange={() =>
                                                            updateBannerStatus(row?._id, !row?.isBlocked)
                                                        }
                                                    />
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Box className="table_actions">
                                                        {hidePermission?.isEdit || UserData?.role === 1 ? (
                                                            <Tooltip title="Edit">
                                                                <IconButton
                                                                    onClick={() => navigate(`/manage-factory-tour/edit/${row?._id}`)}
                                                                >
                                                                    <ModeEditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null}
                                                        {hidePermission?.isDelete || UserData?.role === 1 ? (
                                                            <Tooltip title="Delete">
                                                                <IconButton>
                                                                    <DeleteIcon
                                                                        onClick={() => {
                                                                            setOpen(true);
                                                                            setSelectedId(row?._id);
                                                                        }}
                                                                    />
                                                                </IconButton>
                                                            </Tooltip>
                                                        ) : null}
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    );
                                })
                            ) : (
                                <TableBody>
                                    <TableCell
                                        align="center"
                                        colSpan={10}
                                        sx={{ color: "#051140" }}
                                    >
                                        No data Found
                                    </TableCell>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                </Card>
                <Pagination
                    module={factoryData}
                    page={page}
                    onPageChange={onPageChange}
                    totalPages={totalPages}
                />
            </div>
            <WarnModal
                name="Factory tour"
                setOpen={setOpen}
                open={open}
                handleDelete={() => handleDeleteFactoryTour(selectedId)}
            />
        </MainContainer>
    );
};

export default FactoryTourIndex;
