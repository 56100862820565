import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { OrderData } from "../../types/General";
import { useNavigate } from "react-router-dom";
import moment from "moment";

type props = {
  orderDetails: OrderData[];
  total: number
}
const OrderHistory = ({ orderDetails, total }: props) => {
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  let totalPages = Math.ceil(total / 10);
  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };
  console.log(orderDetails, "orderDetails........");

  return (
    <Card sx={{ mt: 4 }} className="cards">
      <Box className="custom_tabs">
        <h2 className="mn_hdng">Order History</h2>
      </Box>
      <TableContainer className="table_container">
        <Box className="heading"></Box>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="center">S.No</TableCell>
              <TableCell align="center">Date</TableCell>
              <TableCell align="center">Order Id</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Product Name</TableCell>
              <TableCell align="center">Quantity</TableCell>
              <TableCell align="center">Total cost</TableCell>
              <TableCell align="center">Status</TableCell>
              <TableCell align="center">Actions</TableCell>
            </TableRow>
          </TableHead>
          {orderDetails?.length ? (
            orderDetails?.map((row, i) => (
              <TableBody>
                <TableRow>
                  <TableCell align="center">{(page - 1) * 10 + i + 1}</TableCell>
                  <TableCell align="center">{moment(row?.createdAt).format("DD-MM-YYYY") || "-"}</TableCell>
                  <TableCell align="center">{row?.orderId}</TableCell>
                  <TableCell align="center">{row?.categoryName}</TableCell>
                  <TableCell align="center">{row?.productVariant?.name}</TableCell>
                  <TableCell align="center">{row?.quantity}</TableCell>
                  <TableCell align="center">AED {" "}{row?.totalPriceOfProduct}</TableCell>
                  <TableCell align="center">
                    {(() => {
                      switch (row?.status) {
                        case 1:
                          return 'Pending';
                        case 2:
                          return 'Ongoing';
                        case 3:
                          return 'Completed';
                        case 4:
                          return 'Cancelled';
                        case 5:
                          return 'Packed';
                        case 6:
                          return 'Shipped';
                        case 7:
                          return 'Rejected';
                        case 8:
                          return 'Returned';
                        case 9:
                          return 'Refunded';
                        default:
                          return '-';
                      }
                    })()}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      onClick={() => navigate(`/product-order-management/details/${row?._id}`, {
                        state: { details: "userView" }
                      })}
                    >
                      <VisibilityIcon
                      />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableBody>
            ))
          ) : (
            <TableBody>
              <TableCell
                align="center"
                colSpan={10}
                sx={{ color: "#051140" }}
              >
                No order history found
              </TableCell>
            </TableBody>
          )
          }

        </Table>
      </TableContainer >
    </Card >
  );
};

export default OrderHistory;
