import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLazyGetUserByIdQuery } from "../../services/users";
import { useParams } from "react-router-dom";
import { UserData } from "../../types/General";
import { showError } from "../../constants/toast";


type props = {
  userDetails: UserData | undefined;
}

const Details = ({ userDetails }: props) => {


  return (
    <Card className="cards">
      <CardContent sx={{ p: 1 }}>
        <Grid container spacing={2} className="view_box">
          <Grid item lg={2} md={2} sm={6} xs={12}>
            <figure className="profile_img">
              <img
                src={userDetails?.image ? (userDetails?.image) : ("/static/images/user_placeholder.png")}
                alt=""
              />
            </figure>
          </Grid>
          <Grid item xs={10} className="view_box_list">
            <Grid container spacing={3}>
              {/* <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Title</Typography>
                  <Typography component="p">{userDetails?.title || "-"}</Typography>
                </Box>
              </Grid> */}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">First Name</Typography>
                  <Typography component="p">{userDetails?.firstName || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Last Name</Typography>
                  <Typography component="p">{userDetails?.lastName || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Phone no</Typography>
                  <Typography component="p"> {userDetails?.countryCode
                    ? (userDetails?.countryCode.includes("+") ? "" : "+") +
                    userDetails?.countryCode
                    : null}{" "}
                    {userDetails?.phone || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Whatsapp no</Typography>
                  <Typography component="p"> {userDetails?.countryCode
                    ? (userDetails?.countryCode.includes("+") ? "" : "+") +
                    userDetails?.countryCode
                    : null}{" "}
                    {userDetails?.phone || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Email</Typography>
                  <Typography component="p">{userDetails?.email || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Designation</Typography>
                  <Typography component="p">{userDetails?.designation || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Account Status</Typography>
                  <Typography component="p">{userDetails?.isBlocked ? "Blocked" : "Active"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Organization Name</Typography>
                  <Typography component="p">{userDetails?.organisationName || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Looking for</Typography>
                  <Typography component="p">{userDetails?.lookingFor || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Quantity</Typography>
                  <Typography component="p">{userDetails?.quantity || "-"}</Typography>
                </Box>
              </Grid>
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Product Requirement </Typography>
                  <Typography component="p">{userDetails?.productRequirement || "-"}</Typography>
                </Box>
              </Grid>

              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">How soon customer purchase</Typography>
                  <Typography component="p">{userDetails?.howSoonPurchasing}</Typography>
                </Box>
              </Grid>
              {userDetails?.freezoneCheckBox ? (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Freezone Company </Typography>
                    <Typography component="p">True</Typography>
                  </Box>
                </Grid>
              ) : ""}

              {userDetails?.mainlandCheckBox ? (
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box>
                    <Typography component="h5">Mainland Company </Typography>
                    <Typography component="p">True</Typography>
                  </Box>
                </Grid>
              ) : ""}
              <Grid item lg={4} md={4} sm={6} xs={12}>
                <Box>
                  <Typography component="h5">Organization Address</Typography>
                  <Typography component="p">{userDetails?.organisationAddress || "-"}</Typography>
                </Box>
              </Grid>

            </Grid>

          </Grid>
        </Grid>
      </CardContent>
    </Card >
  );
};

export default Details;
