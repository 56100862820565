import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, TruckCategory, categoryArray, subCategoryType } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    query?: string;
};
type GetSubParams = {
    page?: number;
    query?: string;
    size?: number;
    id?: string;
    all?: boolean;
}

type AllSubcategories = {
    count: number;
    pages: number;
    subCategory: subCategoryType[]
}

export const CategoryApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        postCategory: builder.mutation<
            CommonResponseType & { data: TruckCategory },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.category,
                method: "POST",
                body,
            }),
        }),
        getAllCategories: builder.query<
            CommonResponseType & { data: categoryArray },
            GetTokenParams
        >({
            query: ({ query, page }) => ({
                url: `${END_POINTS.category}?page=${page}&search=${query}`,
                method: "GET",
            }),
        }),

        getCategories: builder.query<
            CommonResponseType & { data: categoryArray },
            {}
        >({
            query: ({ }) => ({
                url: `${END_POINTS.category}`,
                method: "GET",
            }),
        }),

        getCategoryById: builder.query<
            CommonResponseType & { data: TruckCategory },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.category}/${id}`,
                method: "GET",
            }),
        }),

        updateCategoryById: builder.mutation<
            CommonResponseType & { data: TruckCategory },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.category}/${id}`,
                method: "PUT",
                body
            }),
        }),

        deleteCategory: builder.mutation<
            CommonResponseType & { data: TruckCategory },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.category}/${id}`,
                method: "DELETE",
            }),
        }),

        // sub-category
        postSubCategory: builder.mutation<
            CommonResponseType & { data: subCategoryType },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.subCategory,
                method: "POST",
                body,
            }),
        }),

        getAllSubCategories: builder.query<
            CommonResponseType & { data: AllSubcategories },
            GetSubParams
        >({
            query: ({ id, query, page, size, all }) => ({
                url: `${END_POINTS.subCategory}/${id}?search=${query}&page=${page}&size=${size}&all=${all}`,
                method: "GET",
            }),
        }),

        getSubCategories: builder.query<
            CommonResponseType & { data: AllSubcategories },
            GetSubParams
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subCategory}/${id}`,
                method: "GET",
            }),
        }),

        getSubCategoryById: builder.query<
            CommonResponseType & { data: subCategoryType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subCategoryById}/${id}`,
                method: "GET",
            }),
        }),

        editSubCategory: builder.mutation<
            CommonResponseType & { data: subCategoryType },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ id, body }) => ({
                url: `${END_POINTS.subCategory}/${id}`,
                method: "PUT",
                body,
            }),
        }),

        deleteSubCategory: builder.mutation<
            CommonResponseType & { data: subCategoryType },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.subCategory}/${id}`,
                method: "DELETE",
            }),
        }),

    })
})

export const {
    //category
    usePostCategoryMutation,
    useLazyGetAllCategoriesQuery,
    useLazyGetCategoriesQuery,
    useLazyGetCategoryByIdQuery,
    useUpdateCategoryByIdMutation,
    useDeleteCategoryMutation,
    //subCategory
    usePostSubCategoryMutation,
    useLazyGetAllSubCategoriesQuery,
    useLazyGetSubCategoriesQuery,
    useLazyGetSubCategoryByIdQuery,
    useEditSubCategoryMutation,
    useDeleteSubCategoryMutation
} = CategoryApi;