import emptySplitApi from "../utils/rtk";
import { END_POINTS } from "../constants/url";
import { CommonBody, GeofenceData, GeofenceRequest, GeofenceResponse } from "../types/General";

type CommonResponseType = {
    statusCode: number;
    message: string;
};

type GetTokenParams = {
    page?: number;
    size?: number;
    query?: string;
};



export const geofenceApi = emptySplitApi.injectEndpoints({
    endpoints: (builder) => ({
        addGeofence: builder.mutation<
            CommonResponseType & { data: GeofenceRequest },
            CommonBody
        >({
            query: (body: any) => ({
                url: END_POINTS.geofence,
                method: "POST",
                body,
            })
        }),

        getGeofence: builder.query<
            CommonResponseType & { data: GeofenceResponse },
            GetTokenParams
        >({
            query: ({ query, page, size }) => ({
                url: `${END_POINTS.geofence}?page=${page}&search=${query}`,
                method: "GET",
            })
        }),
        getAllGeofence: builder.query<
            CommonResponseType & { data: GeofenceResponse },
            {}
        >({
            query: () => ({
                url: END_POINTS.geofence,
                method: "GET",
            })
        }),

        getGeofenceById: builder.query<
            CommonResponseType & { data: GeofenceData },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.geofence}/${id}`,
                method: "GET",
            }),
        }),

        updateGeofence: builder.mutation<
            CommonResponseType & { data: GeofenceData },
            { id: string | undefined, body: CommonBody }
        >({
            query: ({ body, id }) => ({
                url: `${END_POINTS.geofence}/${id}`,
                method: "PUT",
                body
            }),
        }),

        deleteGeofence: builder.mutation<
            CommonResponseType & { data: GeofenceRequest },
            { id: string | undefined }
        >({
            query: ({ id }) => ({
                url: `${END_POINTS.geofence}/${id}`,
                method: "DELETE",
            }),
        }),
    })
})

export const {
    useAddGeofenceMutation,
    useLazyGetGeofenceQuery,
    useLazyGetAllGeofenceQuery,
    useLazyGetGeofenceByIdQuery,
    useUpdateGeofenceMutation,
    useDeleteGeofenceMutation

} = geofenceApi;